.job-form {

    padding: 50px 0;

    .job-form-holder {

        text-align: center;

        .job-form-header {

            height: 500px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

        }

        .job-form-description {

            text-align: left;
            padding: 10px 0;
            margin: 30px 0 0 0;
            border-bottom: 1px solid $aluval-blue;

            h3 {

                font-size: 26px;
                color: $aluval-brown;
                text-transform: uppercase;
                margin-bottom: 10px;

                @include screen-fullhd {

                    font-size: 34px;
                    margin-bottom: 30px;

                }

            }

            p {

                font-size: 18px;
                color: $aluval-black-grey;

                @include screen-fullhd {

                    font-size: 26px;

                }

            }

        }

        .job-form-contact {

            text-align: left;
            border-bottom: 1px solid $aluval-blue;
            padding: 40px 0;

            .text-group {

                margin-bottom: 20px;

                label {

                    display: block;
                    color: $aluval-black-grey;
                    font-size: 18px;

                    @include screen-fullhd {

                        font-size: 26px;

                    }

                }

                .text-input {

                    height: 40px;
                    width: 100%;
                    border: 1px solid $aluval-blue;
                    border-radius: 0;
                    padding: 10px 20px;
                    font-size: 16px;
                    color: $aluval-black-grey;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                    @include screen-fullhd {

                        height: 50px;
                        font-size: 22px;

                    }

                }

            }

            .file-group {

                .text-file-hidden {

                    display: none;

                }

                label {

                    display: block;
                    color: $aluval-black-grey;
                    font-size: 18px;

                    @include screen-fullhd {

                        font-size: 26px;

                    }

                }

                .file-group-line {

                    span {

                        display: inline-block;
                        max-width: 45%;
                        font-size: 18px;
                        color: $aluval-black-grey;
                        vertical-align: middle;
                        padding: 30px 0;
                        -ms-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        overflow: hidden;

                        @include screen-md {

                            padding: 0 30px;

                        }

                        @include screen-lg {

                            max-width: 75%;

                        }

                        @include screen-fullhd {

                            font-size: 26px;

                        }

                    }

                    .file-group-btn {

                        width: 100%;
                        font-size: 18px;
                        padding: 10px 30px;
                        color: $aluval-blue;
                        background: $aluval-white;
                        border: 1px solid $aluval-blue;
                        border-radius: 0;
                        text-align: left;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;

                        &.submit {

                            background: $aluval-blue;
                            color: $aluval-white;

                        }

                        @include screen-md {

                            width: auto;
                            min-width: 180px;

                            &.submit {

                                float: right;

                            }

                        }

                        @include screen-fullhd {

                            font-size: 26px;

                        }

                    }

                }

            }

        }

    }

}
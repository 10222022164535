.products-samples-description {

    .products-samples-description-holder {

        text-align: center;

        .products-samples-description-content {

            text-align: left ;

            .icon-aluval {

                position: absolute;
                top: 0;
                left: 0;
                width: 100px;
                height: 100px;

            }

            h3 {

                font-size: 24px;
                text-transform: uppercase;
                color: $aluval-blue;
                margin: 0 0 20px 120px;
                padding: 0;

            }

            p {

                font-size: 18px;
                color: $aluval-black-grey;
                margin: 0 0 20px 120px;
                padding: 0;

            }

        }

    }

}
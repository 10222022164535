.news-posts {

    padding: 30px 0;
    background: $aluval-grey;

    .news-posts-holder {

        text-align: center;

        .news-posts-items {

            margin: 0 -15px;

            ul {

                display: inline-block;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;

                li {

                    display: inline-block;
                    width: 100%;
                    float: left;
                    padding: 0 15px;
                    margin-bottom: 50px;

                    @include screen-md {

                        width: 50%;

                    }

                    @include screen-xl {

                        width: 33.33%;

                    }

                    .news-content-box {

                        a {

                            text-decoration: none;

                        }

                        .news-content-box-image {

                            height: 175px;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;

                            @include screen-lg {

                                height: 190px;

                            }

                            @include screen-xl {

                                height: 200px;

                            }

                            @include screen-xxl {

                                height: 240px;

                            }

                            @include screen-fullhd {

                                height: 280px;

                            }

                        }

                        .news-content-box-date {

                            padding: 5px 0;

                            .icon {

                                width: 50px;
                                height: 50px;

                                @include screen-fullhd {

                                    width: 70px;
                                    height: 70px;

                                }

                            }

                            span {

                                font-size: 20px;
                                float: right;
                                line-height: 65px;

                                @include screen-xl {

                                    font-size: 20px;
                                    line-height: 70px;

                                }

                            }

                        }

                        .news-content-box-link {

                            height: auto;
                            padding: 15px 100px 10px 20px;
                            background: $aluval-brown;

                            @include screen-lg {

                                height: 90px;
                                padding: 20px 100px 10px 20px;

                            }

                            @include screen-xl {

                                height: 90px;
                                padding: 20px 100px 10px 20px;

                            }

                            @include screen-fullhd {

                                height: 120px;
                                padding: 25px 100px 10px 20px;

                            }

                            span {

                                text-transform: uppercase;
                                color: $aluval-white;
                                font-size: 19px;
                                line-height: 22px;

                                @include screen-lg {

                                    font-size: 18px;
                                    line-height: 24px;

                                }

                                @include screen-xl {

                                    font-size: 18px;
                                    line-height: 26px;

                                }

                                @include screen-xxl {

                                    font-size: 22px;

                                }

                                @include screen-fullhd {

                                    font-size: 28px;
                                    line-height: 36px;

                                }

                            }

                            .icon {

                                position: absolute;
                                width: 40px;
                                height: 40px;
                                bottom: 15px;
                                right: 15px;

                                @include screen-lg {

                                    width: 50px;
                                    height: 50px;
                                    bottom: 20px;

                                }

                                @include screen-xl {

                                    bottom: 15px;

                                }

                            }

                            &.active {

                                background: $aluval-blue;

                            }


                        }

                    }

                }

            }

        }

    }

}
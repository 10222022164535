.home-pre-footer {

    padding: 10px 0 30px 0;

    .home-pre-footer-newsletter {

        border: 1px solid $aluval-grey;
        border-radius: 5px;
        padding: 5px 5px;
        margin-bottom: 20px;

        @include screen-lg {

            margin-bottom: 0;

        }

        .home-pre-footer-newsletter-title {

            background: #9ab6c7;
            padding: 20px 10px 15px 50px;
            margin-bottom: 5px;

            @include screen-lg {

                padding: 10px 10px 5px 50px;

            }

            .icon-newsletter {

                position: absolute;
                top: 12px;
                left: 0;
                height: 42px;
                width: 42px;

                @include screen-lg {

                    top: -2px;

                }

            }

            h3 {

                font-size: 20px;
                text-transform: uppercase;
                color: $aluval-white;
                margin: 0;

            }

        }

        .home-pre-footer-newsletter-form {

            input,
            .select-placeholder {

                display: block;
                /*height: 64px;*/
                width: 100%;
                background: $aluval-grey;
                border: none;
                margin-bottom: 5px;
                padding: 7px 15px;
                font-size: 16px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                @include screen-xxl {

                    padding: 10px 15px;

                }

                &::placeholder {

                    opacity: 1;
                    color: $aluval-placeholder;
                    text-transform: uppercase;
                    font-size: 16px;

                }

                &:-ms-input-placeholder,
                &::-ms-input-placeholder {

                    opacity: 1;
                    color: $aluval-placeholder;
                    text-transform: uppercase;
                    font-size: 16px;

                }

            }

            .select-placeholder {

                color: $aluval-placeholder;
                text-transform: uppercase;
                padding: 7px 15px;
                /*line-height: 68px;*/
                font-size: 16px;
                cursor: pointer;

                @include screen-xxl {

                    padding: 10px 15px;

                }

                .icon-arrow-right {

                    height: 12px;
                    width: 12px;

                }

            }

            select {

                display: none;

            }

        }

        .home-pre-footer-newsletter-button {

            .home-pre-footer-newsletter-btn {

                display: block;
                width: 100%;
                text-transform: uppercase;
                text-align: left;
                font-size: 16px;
                color: $aluval-white;
                padding: 7px 15px;
                background: $aluval-brown;
                border: none;

                @include screen-xxl {

                    padding: 10px 15px;

                }

            }

        }

    }

    .home-pre-footer-banner {

        height: 220px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 20px;

        @include screen-lg {

            /*height: 220px;*/
            margin-bottom: 0;

        }

        @include screen-xxl {

            height: 280px;

        }

        @include screen-fullhd {

            height: 360px;

        }

    }

}
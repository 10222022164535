.home-last-news {

    padding: 50px 0 0 0;

    .home-last-news-separator {

        height: 1px;
        background: $aluval-blue;
        margin: 20px 0;

    }

    .home-last-news-header {

        margin-bottom: 20px;

        .home-last-news-title {

            padding-left: 40px;

            .icon-slash {

                position: absolute;
                top: -2px;
                left: -5px;
                width: 35px;
                height: 35px;

            }

            h3 {

                font-family: 'MaaxMedium', sans-serif;
                text-transform: uppercase;
                color: $aluval-brown;
                font-size: 24px;

                @include screen-lg {

                    font-size: 28px;

                }

            }

        }

        .home-last-news-header-line {

            top: 4px;
            height: 20px;
            width: 100%;
            background: $aluval-blue;

        }

    }

    .home-last-news-content {

        .home-last-news-content-box {

            margin-bottom: 20px;

            @include screen-lg {

                margin-bottom: 0;

            }

            a {

                text-decoration: none;

            }

            .home-last-news-content-box-image {

                height: 175px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                @include screen-lg {

                    height: 190px;

                }

                @include screen-xl {

                    height: 200px;

                }

                @include screen-xxl {

                    height: 240px;

                }

                @include screen-fullhd {

                    height: 280px;

                }

            }

            .home-last-news-content-box-date {

                padding: 5px 0;

                .icon {

                    width: 50px;
                    height: 50px;

                    @include screen-fullhd {

                        width: 70px;
                        height: 70px;

                    }

                }

                span {

                    font-size: 20px;
                    float: right;
                    line-height: 65px;

                    @include screen-xl {

                        font-size: 20px;
                        line-height: 70px;

                    }

                }

            }

            .home-last-news-content-box-link {

                height: auto;
                padding: 15px 100px 10px 20px;
                background: $aluval-brown;

                @include screen-lg {

                    height: 90px;
                    padding: 20px 100px 10px 20px;

                }

                @include screen-xl {

                    height: 90px;
                    padding: 20px 100px 10px 20px;

                }

                @include screen-fullhd {

                    height: 120px;
                    padding: 25px 100px 10px 20px;

                }

                span {

                    text-transform: uppercase;
                    color: $aluval-white;
                    font-size: 19px;
                    line-height: 22px;

                    @include screen-lg {

                        font-size: 18px;
                        line-height: 24px;

                    }

                    @include screen-xl {

                        font-size: 18px;
                        line-height: 26px;

                    }

                    @include screen-xxl {

                        font-size: 22px;

                    }

                    @include screen-fullhd {

                        font-size: 28px;
                        line-height: 36px;

                    }

                }

                .icon {

                    position: absolute;
                    width: 40px;
                    height: 40px;
                    bottom: 15px;
                    right: 15px;

                    @include screen-lg {

                        width: 50px;
                        height: 50px;
                        bottom: 20px;

                    }

                    @include screen-xl {

                        bottom: 15px;

                    }

                }

                &.active {

                    background: $aluval-blue;

                }


            }

        }

    }

    .home-last-news-footer {

        padding-top: 15px;

        .icon {

            width: 105px;
            height: 105px;

            @include screen-lg {

                width: 70px;
                height: 70px;

            }

            @include screen-fullhd {

                width: 105px;
                height: 105px;

            }

        }

    }

    .home-last-news-facebook {

        height: 100%;
        text-align: center;

        .home-last-news-facebook-header,
        .home-last-news-facebook-content-image {

            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

        }

        .home-last-news-facebook-header {

            height: 110px;

        }

        .home-last-news-facebook-content {

            background: $aluval-grey;
            padding: 10px 15px;

        }

        .home-last-news-facebook-content-image {

            height: 315px;

            @include screen-lg {

                height: 360px;

            }

            @include screen-xl {

                height: 465px;

            }

            @include screen-xxl {

                height: 535px;

            }


        }

        iframe {

            max-width: 100% !important;
            margin: 0 auto;

        }

    }

}
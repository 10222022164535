.contact-location-detail {

    margin-top: 30px;
    margin-bottom: 30px;

    .contact-location-detail-holder {

        display: inline-block;
        width: 100%;
        text-align: center;

        .contact-location-detail-left {

            display: inline-block;
            width: 100%;
            height: auto;
            min-height: 1px;
            float: left;
            text-align: left;

            @include screen-md {

                width: 25%;
                height: 450px;

            }

            .contact-location-detail-map-info {


                .title {

                    color: $aluval-blue;
                    text-transform: uppercase;
                    font-size: 18px;

                    @include screen-fullhd {

                        font-size: 26px;

                    }

                }

                .address {

                    color: $aluval-black-grey;
                    font-size: 14px;
                    margin: 0;
                    padding: 0;

                    @include screen-fullhd {

                        font-size: 18px;

                    }

                }

                .mail,
                .link,
                .video {

                    display: block;
                    color: $aluval-brown;
                    font-size: 14px;
                    text-decoration: none;

                    @include screen-fullhd {

                        font-size: 18px;

                    }

                }

                .link,
                .video {

                    color: $aluval-blue;

                }

                .separator {

                    display: block;
                    width: 80%;
                    height: 1px;
                    background: $aluval-blue;
                    margin: 10px 0;

                }

            }

            .icon-aluval {

                position: absolute;
                bottom: 40px;
                left: initial;
                right: 0;
                width: 100px;
                height: 100px;

                @include screen-md {

                    bottom: -15px;
                    left: -10px;
                    right: initial;
                    width: 150px;
                    height: 150px;

                }

            }

        }

        .contact-location-detail-right {

            display: inline-block;
            width: 100%;
            height: 250px;
            float: left;

            @include screen-md {

                width: 75%;
                height: 450px;

            }

            .contact-location-detail-map-holder {

                height: 100%;
                padding: 15px 15px;
                background: $aluval-grey;

                .contact-location-detail-map {

                    height: 100%;
                    width: 100%;
                    border: 5px solid $aluval-white;

                }

            }

        }

    }

}
.wine-rack-bottle-x3 {

    .wine-rack-bottle-x3-holder {

        text-align: center;

        .wine-rack-bottle-x3-title {

            margin: 15px 0 70px 0;

            h2 {

                font-size: 16px;
                font-family: Max, sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 22px;
                color: $aluval-blue-dark;
                margin: 0;
                padding: 0;

                @include screen-md {

                    font-size: 28px;
                    line-height: 20px;

                }

                @include screen-lg {

                    font-size: 36px;
                    padding: 40px 0 20px 0;
                    line-height: 45px;

                }

                @include screen-fullhd{

                    font-size: 40px;
                    line-height: 80px;

                }

            }

            .icon-slash {

                width: 30px;
                height: 30px;
                top: 10px;

                @include screen-md {

                    width: 50px;
                    height: 50px;
                    top: 15px;

                }

                @include screen-lg {

                    width: 60px;
                    height: 60px;
                    top: 15px;

                }

            }


        }

        .wine-rack-bottle-x3-description {

            p {

                font-family: Maax, sans-serif;
                font-weight: 300;
                font-size: 18px;
                line-height: 22px;
                text-align: justify;
                color: $aluval-blue-dark;
                max-width: 730px;
                margin: 0 auto;

            }

        }

        .wine-rack-bottle-x3-product-image {

            width: 100%;

            img {

                width: 100%;
                height: auto;

            }

        }

        .wine-rack-bottle-x3-specs {

            .col-left,
            .col-right {

                text-align: center;

                .icon-wine-rack-modular-logo {

                    width: 230px;
                    height: 50px;
                    margin: 0 0 20px 0;

                }

                p {

                    font-family: 'Maax', sans-serif;
                    font-weight: 300;
                    text-align: justify;
                    font-size: 18px;
                    color: $aluval-blue-dark;
                    line-height: 28px;

                }

                img {

                    width: 100%;
                    height: auto;

                }

                @include screen-md {

                    .icon-wine-rack-modular-logo {

                        width: 330px;
                        height: 75px;
                        margin: 0 0 20px 0;

                    }

                }

            }

            @include screen-lg {

                .col-left,
                .col-right {

                    display: inline-block;
                    width: 50%;
                    float: left;
                    text-align: left;

                }

                .col-left {

                    width: 35%;
                    margin: 150px 0 0 0;

                }

                .col-right {

                    width: 65%;

                }

            }

        }

        .wine-rack-bottle-x3-info {

            .btn {

                font-family: Maax, sans-serif;
                font-size: 20px;
                text-transform: uppercase;
                text-decoration: none;
                color: $aluval-white;
                background: $aluval-blue-dark;
                padding: 5px 30px;
                border-radius: 0;

            }
        }

        .wine-rack-bottle-x3-separator {

            display: inline-block;
            width: 100%;
            padding: 10px 0 10px 0;
            margin: 30px 0 30px 0;
            background-image: url('../img/bg-section.png');
            background-repeat: repeat;

            @include screen-md {

                margin: 120px 0 120px 0;

            }

        }

    }

    .wine-rack-bottle-x3-separator {

        display: inline-block;
        width: 100%;
        padding: 10px 0 10px 0;
        margin: 30px 0 30px 0;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        @include screen-md {

            margin: 120px 0 120px 0;

        }

    }

}
.services-process {

    display: inline-block;
    width: 100%;

    .services-process-holder {

        text-align: center;

        .services-process-title {

            width: 100%;
            float: left;
            text-align: center;

            h1 {

                display: inline-block;
                font-size: 30px;
                font-family: Maax, sans-serif;
                font-weight: 300;
                text-transform: uppercase;
                text-align: center;
                padding: 20px 0 0 0;
                margin: 0 0 40px 0;
                color: $aluval-brown;
                line-height: 22px;

                @include screen-md {

                    font-size: 36px;
                    padding: 40px 0 0 0px;
                    line-height: 45px;

                }

                @include screen-fullhd {

                    font-size: 50px;
                    line-height: 30px;

                }

                .icon-slash {

                    width: 30px;
                    height: 30px;
                    top: 5px;

                    @include screen-md {

                        width: 60px;
                        height: 60px;
                        top: 15px;
                        margin-right: 10px;

                    }

                    @include screen-fullhd {

                        width: 75px;
                        height: 75px;
                        top: 15px;
                        margin-right: 10px;

                    }

                }

            }

            p {

                font-size: 18px;
                text-align: left;
                color: $aluval-black-grey;

                strong {

                    color: $aluval-blue;

                }

            }

        }

        .services-process-selector {

            text-align: center;

            p {

                font-size: 18px;
                text-align: left;
                color: $aluval-black-grey;

                strong {

                    color: $aluval-blue;

                }

            }

            .services-process-selector-wheel {

                overflow: hidden;
                width: 100%;

                .wheel {

                    display: inline-block;
                    width: 1065px;
                    height: 1065px;
                    margin: 0 auto;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;

                }

                svg {

                    width: 500px !important;
                    height: auto !important;
                    max-width: initial !important;
                    max-height: 100% !important;
                    margin-left: -75px !important;
                    margin-top: -35px !important;
                    margin-bottom: -40px !important;

                    @include screen-md {

                        width: 900px !important;
                        margin-left: -15% !important;
                        margin-top: -60px !important;
                        margin-bottom: -90px !important;

                    }

                    @include screen-lg {

                        width: auto !important;
                        height: auto !important;
                        margin-left: 0 !important

                    }

                    @include screen-xl {

                        margin-top: -90px !important;
                        margin-bottom: -120px !important;

                    }

                    #fase_1,
                    #fase_2,
                    #fase_3,
                    #fase_4,
                    #fase_5,
                    #fase_6 {

                        #fase_1_bg,
                        #fase_2_bg,
                        #fase_3_bg,
                        #fase_4_bg,
                        #fase_5_bg,
                        #fase_6_bg,
                        #fase_1_text_phase,
                        #fase_2_text_phase,
                        #fase_3_text_phase,
                        #fase_4_text_phase,
                        #fase_5_text_phase,
                        #fase_6_text_phase,
                        #fase_1_text_title,
                        #fase_2_text_title_1,
                        #fase_2_text_title_2,
                        #fase_3_text_title,
                        #fase_4_text_title_1,
                        #fase_4_text_title_2,
                        #fase_5_text_title,
                        #fase_6_text_title,
                        #fase_1_text_line_1,
                        #fase_1_text_line_2,
                        #fase_2_text_line_1,
                        #fase_3_text_line_1,
                        #fase_3_text_line_2,
                        #fase_3_text_line_3,
                        #fase_4_text_line_1,
                        #fase_4_text_line_2,
                        #fase_5_text_line_1,
                        #fase_5_text_line_2,
                        #fase_5_text_line_3,
                        #fase_6_text_line {

                            @extend .transition-ease-in-out;

                        }

                        #fase_1_bg,
                        #fase_2_bg,
                        #fase_3_bg,
                        #fase_4_bg,
                        #fase_5_bg,
                        #fase_6_bg {

                            fill: #fff;

                        }

                        #fase_1_text_phase,
                        #fase_2_text_phase,
                        #fase_3_text_phase,
                        #fase_4_text_phase,
                        #fase_5_text_phase,
                        #fase_6_text_phase {

                            fill: $aluval-brown;/*#744F3B;*/

                        }

                        #fase_1_text_title,
                        #fase_2_text_title_1,
                        #fase_2_text_title_2,
                        #fase_3_text_title,
                        #fase_4_text_title_1,
                        #fase_4_text_title_2,
                        #fase_5_text_title,
                        #fase_6_text_title {

                            fill: $aluval-blue;/*#9CB7C8;*/

                        }

                        #fase_1_text_line_1,
                        #fase_1_text_line_2,
                        #fase_2_text_line_1,
                        #fase_3_text_line_1,
                        #fase_3_text_line_2,
                        #fase_3_text_line_3,
                        #fase_4_text_line_1,
                        #fase_4_text_line_2,
                        #fase_5_text_line_1,
                        #fase_5_text_line_2,
                        #fase_5_text_line_3,
                        #fase_6_text_line {

                            fill: $aluval-black-grey;/*#676767;*/

                        }

                        &:hover {

                            cursor: pointer !important;

                            #fase_1_bg,
                            #fase_3_bg,
                            #fase_5_bg {

                                fill: $aluval-ocre-2;

                            }

                            #fase_2_bg,
                            #fase_4_bg,
                            #fase_6_bg {

                                fill: $aluval-blue;

                            }

                            #fase_1_text_phase,
                            #fase_2_text_phase,
                            #fase_3_text_phase,
                            #fase_4_text_phase,
                            #fase_5_text_phase,
                            #fase_6_text_phase,
                            #fase_1_text_title,
                            #fase_2_text_title_1,
                            #fase_2_text_title_2,
                            #fase_3_text_title,
                            #fase_4_text_title_1,
                            #fase_4_text_title_2,
                            #fase_5_text_title,
                            #fase_6_text_title,
                            #fase_1_text_line_1,
                            #fase_1_text_line_2,
                            #fase_2_text_line_1,
                            #fase_3_text_line_1,
                            #fase_3_text_line_2,
                            #fase_3_text_line_3,
                            #fase_4_text_line_1,
                            #fase_4_text_line_2,
                            #fase_5_text_line_1,
                            #fase_5_text_line_2,
                            #fase_5_text_line_3,
                            #fase_6_text_line {

                                fill: $aluval-white;

                            }

                        }

                    }

                }

            }

        }

        .services-process-separator {

            width: 100%;
            height: 1px;
            background: $aluval-blue;
            margin: 50px 0;

        }

    }

}
.footer {

    height: auto;
    padding: 50px 0;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include screen-lg {

        height: 200px;

    }

    .footer-left {

        padding: 0 0;

        @include screen-lg {

            padding: 0 0 0 280px;

        }

        .icon-aluval {

            display: none;
            position: absolute;
            top: -85px;
            left: 0;
            height: 260px;
            width: 260px;
            opacity: .7;

            @include screen-lg {

                display: block;

            }

        }

        .footer-contact {

            text-align: center;

            @include screen-lg {

                text-align: left;

            }

            .phone {

                display: block;
                color: $aluval-white;

            }

            .title {

                display: block;
                color: $aluval-white;

            }

            .link {

                display: block;

                a {

                    text-decoration: none;
                    color: $aluval-blue;

                }

            }

            .rrss {

                display: inline-block;
                list-style: none;
                margin: 0;
                padding: 0;

                li {

                    float: left;

                    a {

                        display: inline-block;

                        .icon-issuu-blue {

                            width: 95px;
                            height: 90px;
                            margin-top: -35px;

                        }

                    }

                }

            }

        }

    }

    .footer-right {

        text-align: center;

        @include screen-lg {

            text-align: right;

        }

        .footer-certifications {

            display: block;

            ul {

                display: inline-block;
                list-style: none;
                margin: 0;
                padding: 0;

                li {

                    float: left;

                    a {

                        display: inline-block;

                        .icon {

                            height: 80px;
                            width: 60px;

                            &.icon-nualicoat {

                                height: 80px;
                                width: 80px;
                                margin: 0 10px;

                            }

                        }

                    }

                }

            }

        }

        .footer-designed-by {

            display: block;
            color: $aluval-white;

            a {

                img {

                    height: 15px;
                    width: auto;
                    vertical-align: top;
                    top: 2px;

                }

            }

        }

    }

}
.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    &.icon-lg {
        width: 40px;
        height: 40px;
    }
}

.icon-aenor { background-image: url('../img/icons/icon-aenor.svg'); }
.icon-aluval { background-image: url('../img/icons/icon-aluval.svg'); }
.icon-arrow-right { background-image: url('../img/icons/icon-arrow-right.svg'); }
.icon-arrow-down { background-image: url('../img/icons/icon-arrow-down.svg'); }
.icon-arrow-down-white { background-image: url('../img/icons/icon-arrow-down-white.svg'); }
.icon-aluval-hogar { background-image: url('../img/icons/icon-aluval-hogar.svg'); }
.icon-calendar-blue { background-image: url('../img/icons/icon-calendar-blue.svg'); }
.icon-calendar-brown { background-image: url('../img/icons/icon-calendar-brown.svg'); }
.icon-close { background-image: url('../img/icons/icon-close.svg'); }
.icon-download { background-image: url('../img/icons/icon-download.svg'); }
.icon-download-blue { background-image: url('../img/icons/icon-download-blue.svg'); }
.icon-facebook { background-image: url('../img/icons/icon-facebook.svg'); }
.icon-facebook-brown { background-image: url('../img/icons/icon-facebook-brown.svg'); }
.icon-facebook-blue { background-image: url('../img/icons/icon-facebook-blue.svg'); }
.icon-ionet { background-image: url('../img/icons/icon-ionet.svg'); }
.icon-issuu-blue { background-image: url('../img/icons/icon-issuu-blue.svg'); }
.icon-lecube { background-image: url('../img/icons/icon-lecube.svg'); }
.icon-menu { background-image: url('../img/icons/icon-menu.svg'); }
.icon-minus-blue { background-image: url('../img/icons/icon-minus-blue.svg'); }
.icon-newsletter { background-image: url('../img/icons/icon-newsletter.svg'); }
.icon-nualicoat { background-image: url('../img/icons/icon-nualicoat.svg'); }
.icon-paginator-left { background-image: url('../img/icons/icon-paginator-left.svg'); }
.icon-paginator-right { background-image: url('../img/icons/icon-paginator-right.svg'); }
.icon-parkingdoor-come-in { background-image: url('../img/icons/parkingdoor-come-in.svg'); }
.icon-parkingdoor-connection { background-image: url('../img/icons/icon-parkingdoor-connection.svg'); }
.icon-parkingdoor-door { background-image: url('../img/icons/icon-parkingdoor-door.svg'); }
.icon-parkingdoor-earnings { background-image: url('../img/icons/icon-parkingdoor-earnings.svg'); }
.icon-parkingdoor-garage { background-image: url('../img/icons/icon-parkingdoor-garage.svg'); }
.icon-parkingdoor-press-button { background-image: url('../img/icons/icon-parkingdoor-press-button.svg'); }
.icon-parkingdoor-profiles { background-image: url('../img/icons/icon-parkingdoor-profiles.svg'); }
.icon-plus { background-image: url('../img/icons/icon-plus.svg'); }
.icon-plus-blue { background-image: url('../img/icons/icon-plus-blue.svg'); }
.icon-search { background-image: url('../img/icons/icon-search.svg'); }
.icon-slash { background-image: url('../img/icons/icon-slash.svg'); }
.icon-slash-brown { background-image: url('../img/icons/icon-slash-brown.svg'); }
.icon-slash-orange { background-image: url('../img/icons/icon-slash-orange.svg'); }
.icon-twitter { background-image: url('../img/icons/icon-twitter.svg'); }
.icon-twitter-brown { background-image: url('../img/icons/icon-twitter-brown.svg'); }
.icon-twitter-blue { background-image: url('../img/icons/icon-twitter-blue.svg'); }
.icon-wine-rack-glasses { background-image: url('../img/icons/icon-wine-rack-glasses.svg'); }
.icon-wine-rack-modular-logo { background-image: url('../img/icons/modular-wine-rack-logo.svg'); }
.icon-youtube { background-image: url('../img/icons/icon-youtube.svg'); }
.icon-youtube-brown { background-image: url('../img/icons/icon-youtube-brown.svg'); }
.icon-youtube-blue { background-image: url('../img/icons/icon-youtube-blue.svg'); }
.parkingdoor-description {

    .parkingdoor-description-holder {

        text-align: center;

        .parkingdoor-description-inner {

            .parkingdoor-description-fake-border {

                position: absolute;
                display: inline-block;

                &.top {

                    top: 0;

                }

                &.right {

                    right: 0;

                }

                &.bottom {

                    bottom: 0;
                }

                &.left {

                    left: 0;

                }

                &.horizontal {

                    height: 5px;
                    width: 80px;
                    background: rgba($aluval-orange, 0.8);

                    @include screen-lg {

                        height: 18px;
                        width: 240px;

                    }

                }

                &.vertical {

                    height: 80px;
                    width: 5px;
                    background: $aluval-orange;

                    &.top {

                        top: 5px;
                    }

                    &.bottom {

                        bottom: 5px;

                    }

                    @include screen-lg {

                        height: 240px;
                        width: 18px;

                        &.top {

                            top: 18px;
                        }

                        &.bottom {

                            bottom: 18px;

                        }

                    }

                }

            }

            .parkingdoor-description-title {

                text-align: left;
                padding: 85px 0 50px 50px;

                img {

                    width: 75%;
                    height: auto;

                }

            }

            .parkingdoor-description-content {

                display: inline-block;
                width: 100%;
                text-align:center;
                padding: 0;

                .col-left,
                .col-right {

                    margin: 0 0 40px 0;
                    padding: 0 20px;

                    img {

                        width: 100%;
                        height: auto;

                    }

                    p {

                        font-family: Maax, sans-serif;
                        font-weight: 300;
                        font-size: 16px;
                        text-align: justify;
                        padding: 0;
                        margin: 0 0 30px 0;
                        color: $aluval-blue-dark;
                        line-height: 26px;

                        strong {

                            font-weight: 900;

                        }

                    }

                }

                @include screen-lg {

                    padding: 0 40px;

                    .col-left,
                    .col-right {

                        display: inline-block;
                        width: 50%;
                        float: left;
                        margin: 0;

                    }

                    .col-left {

                        width: 40%;

                    }

                    .col-right {

                        width: 60%;

                    }

                }

            }

            .parkingdoor-description-image {

                text-align: center;
                padding: 120px 0 120px;

                .icon-parkingdoor-come-in {

                    width: 220px;
                    height: 110px;

                }

            }
        }
    }

    .parkingdoor-description-separator {

        display: inline-block;
        width: 100%;
        padding: 10px 0 10px 0;
        margin: 30px 0 10px 0;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        @include screen-md {

            margin: 180px 0 120px 0;

        }

    }
}
section.product-detail {

    background: $aluval-grey;
    padding: 50px 0;

    .product-detail-holder {

        text-align: center;

        .product-detail-inner {

            text-align: left;

            .product-detail-item-box {

                margin-bottom: 20px;

                .product-detail-item-box-img {

                    height: 320px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    @include screen-fullhd {

                        height: 460px;

                    }

                }

                .product-detail-item-box-name {

                    height: 60px;
                    background-image: url('../img/static/products/products-box-btn-hover.jpg');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    text-transform: uppercase;
                    line-height: 65px;
                    font-size: 18px;
                    font-weight: 900;
                    color: $aluval-white;
                    padding: 0 40px;

                    @include screen-fullhd {

                        height: 90px;
                        line-height: 90px;
                        font-size: 28px;

                    }

                    .icon-download {

                        float: right;
                        height: 55px;
                        width: 55px;
                        margin-right: -30px;

                        @include screen-fullhd {

                            height: 90px;
                            width: 90px;

                        }

                    }

                }

            }

            .product-detail-tabs {

                margin-bottom: 20px;

                @include screen-lg {

                    margin-bottom: 0;

                }

                ul {

                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {

                        a {

                            font-size: 22px;
                            color: $aluval-blue;
                            text-decoration: none;

                            @include screen-fullhd {

                                font-size: 25px;

                            }

                            .icon-slash-brown {

                                display: none;

                            }

                        }

                        &.active {

                            a {

                                color: $aluval-brown;

                                .icon-slash-brown {

                                    display: inline-block;
                                    width: 30px;
                                    height: 30px;
                                    top: 5px;
                                    margin-right: 5px;

                                }

                            }

                        }

                    }

                }

            }

            .product-detail-content {

                padding: 0 10px;

                .product-detail-header {

                    .title {

                        background: $aluval-white;
                        color: $aluval-blue;
                        text-transform: uppercase;
                        font-weight: 900;
                        font-size: 18px;
                        padding: 5px 10px 0 10px;
                        margin: 0 -10px;

                        @include screen-md {

                            font-size: 24px;

                        }

                        @include screen-fullhd {

                            font-size: 28px;

                        }

                    }

                    .subtitle {

                        color: $aluval-brown;
                        font-size: 18px;
                        padding: 20px 0 10px 0;

                        @include screen-md {

                            font-size: 24px;

                        }

                        @include screen-fullhd {

                            font-size: 28px;

                        }

                    }

                }

                .product-detail-tab-pane {

                    display: none;

                    &.active {

                        display: block;

                    }


                    .product-detail-block-item {

                        padding-bottom: 10px;
                        margin-bottom: 20px;
                        border-bottom: 2px solid $aluval-blue;

                        h5 {

                            font-size: 16px;
                            font-weight: 900;
                            color: $aluval-blue;
                            text-transform: uppercase;
                            margin: 0 0 10px 0;
                            padding: 0;

                            @include screen-md {

                                font-size: 18px;

                            }

                            @include screen-fullhd {

                                font-size: 29px;

                            }

                        }

                        ul {

                            list-style: none;
                            padding: 0;
                            margin: 0;

                            li {

                                font-size: 14px;
                                color: $aluval-black-grey;

                                @include screen-md {

                                    font-size: 16px;

                                }

                                @include screen-fullhd {

                                    font-size: 18px;

                                }

                            }

                        }

                        &.table-style {

                            ul {

                                display: table;

                                li {

                                    display: table-row;

                                    strong,
                                    span {

                                        display: table-cell;
                                        vertical-align: top;

                                    }

                                }

                            }

                        }

                        .table {

                            border: none;

                            td {

                                border: none;
                                padding: 0;
                                font-size: 16px;

                                &:first-child {

                                    width: 110px;

                                    @include screen-md {

                                        width: 180px;

                                    }

                                }

                            }

                        }

                        strong {

                            font-weight: 900;

                        }

                        span.left,
                        strong.left {

                            width: 110px;

                            @include screen-md {

                                width: 180px;

                            }

                        }

                        &.no-separation {

                            border-bottom: none;

                            h5 {

                                margin-bottom: 30px;

                            }

                        }

                        .product-detail-mounting-scheme {

                            margin: 0 -15px;

                            ul {

                                display: inline-block;
                                width: 100%;

                                li {

                                    width: 50%;
                                    float: left;
                                    padding: 0 15px;
                                    margin-bottom: 20px;

                                    .product-detail-mounting-scheme-item {

                                        height: 350px;
                                        padding: 10px 0;
                                        background-image: url('../img/bg-section.png');
                                        background-repeat: repeat;

                                        a {

                                            position: relative;
                                            display: inline-block;
                                            height: 100%;
                                            max-height: 350px;
                                            left: 50%;
                                            top: 50%;
                                            background: $aluval-white;
                                            text-align: right;
                                            padding: 0 10px;
                                            -webkit-transform: translate(-50%,-50%);
                                            -moz-transform: translate(-50%,-50%);
                                            -ms-transform: translate(-50%,-50%);
                                            -o-transform: translate(-50%,-50%);
                                            transform: translate(-50%,-50%);

                                            img {

                                                max-height: 100%;
                                                max-width: 100%;
                                                display: block;

                                            }

                                            .icon {

                                                position: absolute;
                                                bottom: 5px;
                                                right: 5px;

                                            }

                                        }

                                    }

                                }

                            }

                        }

                    }

                    .product-detail-catalog-list-holder {

                        display: none;

                        &.active {

                            display: block;

                        }

                        .product-detail-catalog-search {

                            margin-bottom: 20px;

                            h3 {

                                text-transform: uppercase;
                                font-size: 18px;
                                font-weight: 900;
                                color: $aluval-blue;

                                @include screen-md {

                                    font-size: 20px;

                                }

                                @include screen-fullhd {

                                    font-size: 29px;

                                }

                            }

                            .product-detail-catalog-search-inner {

                                border: none;
                                border-collapse: collapse;

                                @include screen-xl {

                                    display: table;
                                    width: 100%;

                                }

                                .product-detail-catalog-search-label {

                                    height: 41px;
                                    background: $aluval-blue;
                                    overflow: hidden;

                                    @include screen-xl {

                                        display: inline-block;
                                        width: 320px;
                                        top: -1px;
                                        vertical-align: middle;

                                    }

                                    .icon-search {

                                        position: absolute;
                                        top: 5px;
                                        left: -5px;
                                        height: 50px;
                                        width: 50px;

                                    }

                                    span {

                                        position: absolute;
                                        top: 0;
                                        left: 50px;
                                        display: inline-block;
                                        color: $aluval-white;
                                        text-transform: uppercase;
                                        font-size: 20px;
                                        font-weight: 900;
                                        line-height: 47px;

                                    }

                                }

                                .input-reference,
                                .input-description {

                                    width: 100%;

                                    @include screen-xl {

                                        display: table-cell;
                                        width: 150px;

                                    }

                                    input {

                                        width: 100%;
                                        height: 41px;
                                        background: $aluval-white;
                                        border: 1px solid $aluval-black-grey;
                                        padding: 5px 10px;

                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        appearance: none;

                                        &::placeholder {

                                            color: $aluval-placeholder;
                                            font-size: 18px;

                                        }

                                        &:-ms-input-placeholder,
                                        &::-ms-input-placeholder {

                                            color: $aluval-placeholder;
                                            font-size: 18px;

                                        }

                                    }

                                }

                                .input-description {

                                    width: auto;

                                    @include screen-xxl {

                                        width: 350px;

                                    }

                                }

                                .button-holder {

                                    @include screen-xl {

                                        display: table-cell;
                                        width: 120px;
                                        top: -1px;

                                    }

                                    .btn {

                                        width: 100%;
                                        height: 41px;
                                        background: $aluval-brown;
                                        text-transform: uppercase;
                                        color: $aluval-white;
                                        font-size: 20px;
                                        font-weight: 900;
                                        border-radius: 0;

                                        @include screen-xl {

                                            width: auto;

                                        }

                                    }

                                }

                            }

                        }

                        .product-detail-catalog-list {

                            ul {

                                display: inline-block;
                                width: 100%;
                                list-style: none;
                                padding: 0;
                                margin: 0;

                                li {

                                    display: inline-block;
                                    width: 100%;
                                    float: left;
                                    padding: 0 0;
                                    margin-bottom: 10px;

                                    @include screen-md {

                                        width: 50%;
                                        padding: 0 5px;

                                    }

                                    @include screen-xl {

                                        width: 25%;

                                    }


                                }

                            }

                        }

                        .product-detail-catalog-item-title {

                            h3 {

                                font-size: 14px;
                                text-transform: uppercase;
                                color: $aluval-blue;
                                margin-bottom: 20px;

                                @include screen-md {

                                    font-size: 18px;

                                }

                                @include screen-xl {

                                    margin-bottom: initial;

                                }

                                @include screen-fullhd {

                                    font-size: 29px;

                                }

                                a {

                                    display: block;
                                    text-decoration: none;
                                    font-weight: 300;
                                    color: $aluval-blue;

                                    @include screen-md {

                                        display: initial;

                                    }

                                }

                                strong {

                                    font-weight: 900;

                                }

                            }

                        }

                        .product-detail-catalog-item-images {

                            display: inline-block;
                            width: 100%;
                            margin: 0 -10px 20px -10px;

                            .item-image-holder {

                                display: inline-block;
                                height: 240px;
                                width: 100%;
                                float: left;
                                padding: 0 10px;
                                margin-bottom: 20px;
                                background: $aluval-white;

                                @include screen-md {

                                    width: 50%;
                                    padding: 0 5px;
                                    margin-bottom: 0;
                                    background: none;

                                }

                                @include screen-xl {

                                    height: 355px;
                                    background: $aluval-white;

                                }

                                .item-image {

                                    width: 100%;
                                    height: 100%;
                                    background-position: center;
                                    background-size: cover;
                                    background-repeat: no-repeat;

                                }

                            }

                        }

                        .product-detail-catalog-item-related {

                            h5 {

                                font-size: 20px;
                                font-weight: 900;
                                color: $aluval-blue;
                                text-transform: uppercase;
                                margin: 0 0 10px 0;
                                padding: 0;

                                @include screen-md {

                                    font-size: 29px;

                                }

                            }

                            ul {

                                &.filters,
                                &.list {

                                    display: inline-block;
                                    width: 100%;
                                    list-style: none;
                                    padding: 0;
                                    margin: 0;

                                    li {

                                        display: inline-block;
                                        width: 100%;
                                        float: left;
                                        font-size: 20px;
                                        color: $aluval-black-grey;
                                        margin-bottom: 0;

                                        @include screen-xl {

                                            width: 33.33%;
                                            margin-bottom: 20px;
                                            font-size: 20px;

                                        }

                                        &.active {

                                            color: $aluval-blue;
                                            font-weight: 900;

                                        }

                                    }

                                }

                                &.list {

                                    li {

                                        width: 100%;
                                        padding: 0 0;

                                        @include screen-md {

                                            width: 50%;
                                            padding: 0 5px;

                                        }

                                        @include screen-xl {

                                            width: 25%;

                                        }

                                    }

                                }

                            }

                        }

                        .product-detail-catalog-list,
                        .product-detail-catalog-item-related {

                            .product-detail-catalog-item {

                                background: $aluval-white;
                                padding: 0 30px 10px 30px;
                                margin-bottom: 20px;

                                @include screen-md {

                                    padding: 0 10px 10px 10px;
                                    margin-bottom: 0;

                                }

                                .product-detail-catalog-item-img {

                                    height: 200px;
                                    margin-bottom: 20px;

                                    @include screen-md {

                                        height: 235px;

                                    }

                                    @include screen-xl {

                                        height: 160px;

                                    }

                                    & > img {

                                        width: 100%;
                                        height: auto;
                                        max-height: 100%;
                                        top: 50%;
                                        -webkit-transform: translateY(-50%);
                                        -moz-transform: translateY(-50%);
                                        -ms-transform: translateY(-50%);
                                        -o-transform: translateY(-50%);
                                        transform: translateY(-50%);

                                    }

                                }

                                .product-detail-catalog-item-name {

                                    min-height: 70px;

                                    a {

                                        display: block;
                                        text-decoration: none;
                                        text-transform: uppercase;
                                        font-size: 14px;
                                        font-weight: 900;
                                        line-height: 20px;
                                        color: $aluval-blue;

                                        @include screen-fullhd {

                                            font-size: 18px;

                                        }

                                    }

                                }

                            }


                        }

                    }

                }

            }

        }

    }

}
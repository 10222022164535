.wine-rack-description {

    .wine-rack-description-holder {

        text-align: center;

        .wine-rack-description-inner {

            .wine-rack-description-fake-border {

                position: absolute;
                display: inline-block;

                &.top {

                    top: 0;

                }

                &.right {

                    right: 0;

                }

                &.bottom {

                    bottom: 0;
                }

                &.left {

                    left: 0;

                }

                &.horizontal {

                    height: 5px;
                    width: 80px;
                    background: rgba($aluval-blue-new, 0.8);

                    @include screen-lg {

                        height: 18px;
                        width: 240px;

                    }

                }

                &.vertical {

                    height: 80px;
                    width: 5px;
                    background: $aluval-blue-new;

                    &.top {

                        top: 5px;
                    }

                    &.bottom {

                        bottom: 5px;

                    }

                    @include screen-lg {

                        height: 240px;
                        width: 18px;

                        &.top {

                            top: 18px;
                        }

                        &.bottom {

                            bottom: 18px;

                        }

                    }

                }

            }

            .wine-rack-description-title {

                display: inline-block;
                text-align: center;
                padding: 85px 0 50px 0;

                h3 {

                    font-family: Maax, sans-serif;
                    font-weight: 300;
                    font-size: 26px;
                    text-transform: uppercase;
                    color: $aluval-black;

                    .title-blue {

                        font-weight: 600;
                        color: $aluval-blue-new;

                    }

                }

                h5 {

                    font-family: MaaxBold, sans-serif;
                    font-weight: 900;
                    font-size: 20px;
                    color: $aluval-blue-new;


                }

                .icon-wine-rack-glasses {

                    height: 75px;
                    width: 65px;
                    margin: 0 0 20px 0;

                }

                .vertical-line {

                    display: none;

                }

                @include screen-lg {

                    text-align: left;
                    padding: 85px 0 50px 255px;

                    h3 {

                        font-size: 40px;

                    }

                    h5 {

                        font-size: 26px;

                    }

                    .icon-wine-rack-glasses {

                        position: absolute;
                        top: 40px;
                        left: 65px;
                        height: 150px;
                        width: 130px;
                        margin: 0;

                    }

                    .vertical-line {

                        position: absolute;
                        display: block;
                        top: 80px;
                        left: 220px;
                        height: 70px;
                        width: 7px;
                        background: $aluval-blue-new;

                    }

                }

            }

            .wine-rack-description-content {

                padding: 0 0;
                text-align:center;

                .wine-rack-description-content-block {

                    display: inline-block;
                    width: 100%;

                }

                .col-left,
                .col-right {

                    padding: 0 20px;

                    p {

                        font-family: Maax, sans-serif;
                        font-weight: 300;
                        font-size: 18px;
                        text-align: justify;
                        padding: 0;
                        margin: 0 0 30px 0;
                        color: $aluval-blue-dark;

                    }

                    @include screen-lg {

                        display: inline-block;
                        width: 50%;
                        float: left;

                    }

                }

                .bottom-image {

                    display: inline-block;
                    width: 50%;
                    margin: 50px auto;

                    img {

                        width: 100%;
                        height: auto;

                    }

                }

                @include screen-lg {

                    padding: 0 40px;

                }

            }
        }
    }

    .wine-rack-description-separator {

        display: inline-block;
        width: 100%;
        padding: 10px 0 10px 0;
        margin: 30px 0 10px 0;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        @include screen-md {

            margin: 180px 0 120px 0;

        }

    }
}
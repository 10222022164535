.services-description {

    display: inline-block;
    width: 100%;

    .services-description-holder {

        text-align: center;
        padding: 30px 0;

        .icon-aluval {

            width: 90px;
            height: 80px;
            float: left;
            margin-right: 10px;
            margin-left: -10px;
            margin-top: -10px;

            @include screen-md {

                width: 90px;
                height: 80px;
                margin-top: 0;
                margin-left: -15px;

            }

            @include screen-fullhd {

                width: 180px;
                height: 190px;
                margin-top: -15px;
                margin-left: -15px;

            }

        }

        p:not(.scss-error-wtf) {

            font-size: 16px;
            text-align: justify;
            color: $aluval-black-grey;

            @include screen-md {

                font-size: 18px;

            }

        }

    }

}
.products-catalogues-content {

    .products-catalogues-content-holder {

        text-align: center;
        padding: 50px 0 50px 0;
        background: $aluval-chronology-bg;

        .products-catalogues-content-tabs {

            margin: 0 -5px;

            ul {

                list-style: none;
                margin: 0;
                padding: 0;

                li {

                    display: none;
                    width: 100%;
                    margin: 0 0 10px 0;
                    padding: 0 5px;

                    &.active {

                        display: inline-block;

                    }

                    .products-catalogue-sample {

                        width: 100%;
                        height: 1600px;
                        background: $aluval-white;
                        border: 1px solid $aluval-blue;

                    }

                    .icon {

                        width: 200px;
                        height: 200px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        -moz-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        -o-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);

                    }


                }

            }

        }

    }

}
section.news-post {

    margin: 50px 0;
    background: $aluval-grey;

    .news-post-holder {

        text-align: center;

        .news-post-box {

            margin: 30px 0;

            .news-post-box-header {

                display: inline-block;
                width: 100%;
                background: $aluval-white;
                border: 1px solid $aluval-blue;
                margin-bottom: 20px;
                padding: 15px 30px 5px 30px;

                .header-left {

                    display: inline-block;
                    float: left;

                    h1 {

                        font-size: 16px;
                        line-height: 30px;
                        color: $aluval-blue;

                        @include screen-md {

                            font-size: 20px;
                            line-height: 44px;

                        }

                    }

                }

                .header-right {

                    display: inline-block;
                    float: right;

                    span {

                        font-size: 16px;
                        line-height: 30px;

                        @include screen-md {

                            font-size: 20px;
                            line-height: 44px;

                        }

                    }

                    .icon {

                        width: 40px;
                        height: 40px;
                        top: -10px;
                        float: left;

                        @include screen-md {

                            width: 50px;
                            height: 50px;
                            top: -10px;

                        }

                    }

                }

            }

            .news-post-box-body {

                display: inline-block;
                width: 100%;
                text-align: left;
                padding: 30px 30px;
                margin-bottom: 15px;
                background: $aluval-white;
                border: 1px solid $aluval-blue;

                .news-post-box-body-image {

                    //display: table;
                    width: 100%;
                    margin-bottom: 40px;

                    .body-img {

                        //display: table-cell;
                        width: 100%;
                        /*height: 180px;*/
                        /*float: left;*/
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;

                        img {

                            height: auto;
                            max-height: 100%;
                            width: 100%;
                            max-width: 100%;

                        }

                        @include screen-md {

                            //width: 85%;
                            /*height: 350px;*/

                        }

                    }

                }

                .news-post-box-body-title {

                    float: left;
                    margin-bottom: 10px;

                    h3 {

                        font-size: 16px;
                        color: $aluval-blue;

                        @include screen-md {

                            font-size: 20px;

                        }

                    }

                }

                .news-post-box-body-content {

                    float: left;
                    text-align: justify;

                    .body-icon {

                        display: none;
                        //width: 15%;
                        //height: 350px;
                        /*float: left;*/

                        @include screen-md {

                            display: block;
                            float: left;

                        }

                        .icon {

                            //position: absolute;
                            //bottom: 0;
                            //right: 0;
                            width: 85px;
                            height: 85px;
                            margin: 0 20px -10px 0;

                            @include screen-lg {

                                width: 120px;
                                height: 120px;

                            }

                        }

                    }

                    p {

                        font-size: 14px;
                        color: $aluval-black-grey;

                        @include screen-md {

                            font-size: 16px;

                        }

                    }

                }

            }

            .news-post-box-button {

                text-align: right;

                .btn {

                    text-transform: uppercase;
                    font-size: 14px;
                    color: $aluval-white;
                    padding: 5px 30px 2px 30px;
                    background: $aluval-brown;
                    border-radius: 0;

                    @include screen-md {

                        font-size: 18px;
                        padding: 5px 40px 2px 40px;

                    }

                }

            }

        }

    }

}
.industrial-slider {

    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;

    .industrial-slider-holder {

        overflow: hidden;
        height: 220px;

        @include screen-md {

            height: 320px;

        }

        @include screen-lg {

            height: 730px;

        }

        .industrial-slider-slides {

            height: 100%;
            margin: 0;
            padding: 0;
            list-style: none;

            .industrial-slider-slide {

                width: 100%;
                height: 100%;
                display: inline-block;
                float: left;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

            }

        }

    }

}
.products-list {

    display: inline-block;
    width: 100%;
    padding: 50px 0 50px 0;
    background-image: url('../img/bg-section.png');
    background-repeat: repeat;

    .products-list-holder {

        text-align: center;

        .products-list-inner {

            margin: 0 -10px;

            ul {

                display: inline-block;
                width: 100%;
                list-style: none;
                margin: 0;
                padding: 0;

                & > li {

                    display: inline-block;
                    width: 100%;
                    float: left;
                    padding: 0 10px;
                    margin-bottom: 20px;

                    @include screen-md {

                        width: 50%;

                    }

                    @include screen-lg {

                        width: 33.33%;

                    }

                    .products-box {

                        background: $aluval-white;
                        cursor: pointer;
                        max-height: 370px;
                        overflow: hidden;

                        @include screen-md {

                            max-height: 340px;

                        }

                        @include screen-lg {

                            max-height: 290px;

                        }

                        @include screen-xl {

                            max-height: 380px;

                        }

                        @include screen-fullhd {

                            max-height: 515px;

                        }

                        .products-box-img {

                            height: 310px;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;

                            @include screen-md {

                                height: 280px;

                            }

                            @include screen-lg {

                                height: 230px;

                            }

                            @include screen-xl {

                                height: 320px;

                            }

                            @include screen-fullhd {

                                height: 450px;

                            }

                            .tag {

                                position: absolute;
                                right: 30px;
                                bottom: 20px;
                                text-transform: uppercase;
                                font-family: MaaxBold, sans-serif;
                                font-size: 26px;
                                color: $aluval-ocre;

                            }

                        }

                        .products-box-description {

                            height: 60px;
                            background-image: url('../img/static/products/products-box-btn-normal.jpg');
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            padding: 0 10px 0 50px;

                            @include screen-fullhd {

                                height: 65px;

                            }

                            a {

                                float: left;
                                text-transform: uppercase;
                                text-decoration: none;
                                font-family: MaaxBold, sans-serif;
                                font-size: 18px;
                                color: $aluval-white;
                                line-height: 60px;

                                &.text-right {

                                    float: right;

                                }

                                .icon-download {

                                    height: 60px;
                                    width: 60px;

                                }

                                @include screen-fullhd {

                                    font-size: 18px;
                                    line-height: 70px;

                                    .icon-download {

                                        height: 65px;
                                        width: 65px;

                                    }

                                }

                            }

                        }

                    }

                    &:hover {

                        .products-box {

                            .products-box-description {

                                background-image: url('../img/static/products/products-box-btn-hover.jpg');

                            }

                        }


                    }

                    &.new {

                        .products-box {

                            .products-box-description {

                                background-image: url('../img/static/products/products-box-btn-new.jpg');

                            }

                        }

                    }


                }

            }

        }

    }

}
.home-slider {

    overflow: hidden;

    .home-slider-slide {

        height: 350px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;

        @include screen-lg {

            height: 500px;
            background-size: 100% auto;
            background-position: center 30%;

        }

        @include screen-xl {

            height: 660px;
            background-position: center 40%;

        }

        .home-slider-slide-holder {

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;

            .home-slider-slide-item {

                width: 100%;
                height: 100%;
                display: inline-block;
                float: left;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

            }

        }

        .home-slider-slide-message {

            position: absolute;
            width: 100%;
            top: 50%;
            text-align: center;
            color: $aluval-white;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);

            .home-slider-slide-message-item {

                display: none;

                &.active {

                    display: block;

                }

                .top {

                    & > span {

                        display: inline-block;
                        height: auto;
                        width: auto;
                        text-transform: uppercase;
                        background: $aluval-blue;
                        font-size: 20px;
                        font-weight: 300;
                        line-height: 27px;
                        padding: 0 30px;

                        @include screen-lg {

                            height: 40px;
                            font-size: 36px;
                            line-height: 45px;

                        }

                        @include screen-xl {

                            height: 65px;
                            font-size: 56px;
                            line-height: 74px;

                        }

                    }


                }

                .bottom {

                    & > span {

                        display: inline-block;
                        height: 25px;
                        text-transform: uppercase;
                        background: $aluval-brown;
                        font-size: 20px;
                        font-weight: bold;
                        padding: 0 10px;
                        line-height: 26px;

                        @include screen-lg {

                            height: 64px;
                            font-size: 60px;
                            line-height: 72px;

                        }

                        @include screen-xl {

                            height: 72px;
                            font-size: 62px;
                            line-height: 82px;

                        }

                    }

                }

            }

        }

        .band {

            position: absolute;
            display: none;
            top: 25%;
            height: 120px;
            width: 100%;
            background: $aluval-white;
            opacity: 0.4;
            -webkit-transform: rotate(-51deg);
            -moz-transform: rotate(-51deg);
            -ms-transform: rotate(-51deg);
            -o-transform: rotate(-51deg);
            transform: rotate(-51deg);

            @include screen-lg {

                display: block;

            }

            &.left {

                left: -325px;

                @include screen-lg {

                    left: -290px;

                }

                @include screen-xl {

                    left: -325px;

                }

            }

            &.right {

                right: 165px;

                @include screen-lg {

                    right: 125px;

                }

                @include screen-xl {

                    right: 160px;

                }

            }

        }

    }

}
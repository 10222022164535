.house-products {

    .house-products-holder {

        text-align: center;

        .house-products-items {

            margin: 0 -10px;

            .house-products-item {

                margin: 0 0 20px 0;
                padding: 0 10px;

                a {

                    display: inline-block;
                    width: 100%;

                    img {

                        width: 100%;
                        height: auto;

                    }

                }

                @include screen-md {

                    display: inline-block;
                    width: 33.33%;
                    float: left;
                    margin: 0;

                }

            }

        }

    }

    .house-products-separator {

        display: inline-block;
        width: 100%;
        padding: 10px 0 10px 0;
        margin: 40px 0 40px 0;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        @include screen-md {

            margin: 120px 0 120px 0;

        }

    }

}
.services-process-steps {

    display: inline-block;
    width: 100%;
    text-align: center;

    .services-process-steps-holder {

        .services-process-steps-item {

            background: $aluval-white;
            overflow: hidden;

            .services-process-steps-item-header {

                text-align: left;
                border-bottom: 1px solid $aluval-blue;
                padding: 10px 0;

                span {

                    display: inline-block;
                    text-transform: uppercase;
                    font-size: 12px;
                    color: $aluval-white;
                    padding: 3px 10px 0 10px;

                    &.tag-big {

                        min-width: initial;

                    }

                    &.bg-ocre {

                        background: $aluval-ocre-3;

                    }

                    &.bg-blue {

                        background: $aluval-blue;

                    }

                    @include screen-md {

                        font-size: 24px;
                        padding: 6px 20px 3px 20px;

                        &.tag-big {

                            min-width: 480px;

                        }

                    }

                }

                .btn-close {

                    width: 24px;
                    height: 24px;
                    background: $aluval-blue;
                    border-radius: 5px;
                    color: $aluval-white;
                    float: right;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;

                    @extend .transition-ease-in-out;

                    .icon-close {

                        width: 24px;
                        height: 24px;

                    }

                    &:hover {

                        background: $aluval-ocre-3;

                    }

                    @include screen-md {

                        width: 45px;
                        height: 45px;

                        .icon-close {

                            width: 45px;
                            height: 45px;

                        }

                    }

                }

            }

            .services-process-steps-item-content {

                border-bottom: 1px solid $aluval-blue;
                padding-bottom: 30px;
                margin-bottom: 50px;

                .services-process-steps-item-content-title {

                    font-size: 18px;
                    color: $aluval-black-grey;
                    text-align: left;
                    border-bottom: 1px solid $aluval-blue;
                    padding: 10px 0;
                    margin-bottom: 20px;

                    @include screen-fullhd {

                        font-size: 20px;

                    }

                }

                .services-process-steps-item-content-image {

                    width: 100%;
                    height: 240px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    margin-bottom: 40px;

                    @include screen-md {

                        height: 550px;

                    }

                }

                .services-process-steps-item-content-text {

                    p {

                        font-size: 16px;
                        color: $aluval-black-grey;
                        text-align: left;

                        strong {

                            color: $aluval-blue;

                        }

                        @include screen-fullhd {

                            font-size: 18px;

                        }

                    }

                }

                .services-process-steps-item-content-map {

                    background: $aluval-chronology-bg;
                    margin-bottom: 50px;

                    img {

                        width: 100%;
                        height: auto;

                    }

                }

            }

        }

    }

}
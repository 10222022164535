.nav-desktop {

    height: auto;
    text-align: center;
    background: $aluval-white;
    border-bottom: 10px solid $aluval-blue;
    z-index: 3;

    @include screen-lg {

        height: 150px;

    }

    .nav-left {

        z-index: 1;

        position: absolute;
        left: 0;
        right: 0;
        bottom: 70px;

        @include screen-lg {

            bottom: 0;
            left: 0;
            right: auto;
            padding-left: 5%;

        }

        @include screen-xxl {

            padding-left: 7%;

        }

        @include screen-fullhd {

            padding-left: 0;

        }

        .social-holder {

            ul {

                display: inline-block;
                list-style: none;
                margin: 0;
                padding: 0;

                li {

                    float: left;

                    a {

                        .icon-facebook,
                        .icon-twitter,
                        .icon-youtube {

                            vertical-align: bottom;

                        }

                        .icon-facebook {

                            &:hover {

                                @extend .icon-facebook-brown;

                            }

                        }

                        .icon-twitter {

                            &:hover {

                                @extend .icon-twitter-brown;

                            }

                        }

                        .icon-youtube {

                            &:hover {

                                @extend .icon-youtube-brown;

                            }

                        }

                    }

                }

            }

        }

    }

    .nav-center {

        z-index: 0;

        .logo-holder {

            height: auto;
            display: inline-block;
            padding-bottom: 100px;

            @include screen-lg {

                height: 140px;
                padding-bottom: 0;

            }

            img {

                height: auto;
                width: 100%;

                @include screen-lg {

                    height: 100%;
                    width: auto;

                }

            }

            .line {

                display: none;
                position: absolute;
                top: 0;
                height: 1px;
                background: $aluval-blue;
                width: 100%;
                -webkit-transform: rotate(-51deg);
                -moz-transform: rotate(-51deg);
                -ms-transform: rotate(-51deg);
                -o-transform: rotate(-51deg);
                transform: rotate(-51deg);

                @include screen-lg {

                    display: block;

                }

                &.left {

                    left: -100px;

                }

                &.right {

                    right: -220px;

                }

            }

        }

    }

    .nav-right {

        z-index: 1;

        position: absolute;
        right: 10px;
        left: 10px;
        bottom: 0;

        @include screen-lg {

            bottom: 0;
            right: 0;
            left: auto;
            padding-right: 5%;

        }

        @include screen-xxl {

            padding-right: 7%;

        }

        @include screen-fullhd {

            padding-right: 0;

        }

        .locale-holder {

            position: absolute;
            left: auto;
            bottom: 30px;
            padding: 0;

            @include screen-lg {

                position: relative;
                display: inline-block;
                vertical-align: bottom;
                padding-bottom: 5px;
                bottom: auto;

            }

            ul {

                list-style: none;
                display: inline-block;
                margin: 0;
                padding: 0;

                li {

                    float: left;

                    a {
                        font-weight: 700;
                        text-decoration: none;
                        text-transform: uppercase;
                        padding: 0 5px;
                        color: $aluval-blue;

                        &:hover {

                            color: $aluval-brown;

                        }

                    }

                    & + li {
                        border-left: 1px solid $aluval-blue;
                    }

                    &.active {

                        a {

                            color: $aluval-brown;

                        }

                    }

                }

            }

        }

        .private-holder {

            padding-left: 0;
            padding-bottom: 10px;
            vertical-align: bottom;

            @include screen-lg {

                display: inline-block;
                padding-left: 30px;

            }

            .private-title {

                font-weight: 700;
                color: $aluval-blue;
                text-transform: uppercase;
                text-align: right;
                padding-right: 20px;

            }

            .private-login {

                @include screen-lg {

                    padding-right: 10px;

                }

                input {

                    width: 125px;
                    border: 1px solid $aluval-grey;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                    @include screen-lg {

                        width: 100px;

                    }

                    @include screen-xl {

                        width: 125px;

                    }

                    &::placeholder {
                        opacity: 1;
                        color: $aluval-placeholder;
                        padding: 0 10px;
                    }

                    &:-ms-input-placeholder,
                    &::-ms-input-placeholder {
                        opacity: 1;
                        color: $aluval-placeholder;
                        padding: 0 10px;
                    }

                }

                .icon {
                    width: 15px;
                    height: 15px;
                    top: 3px;
                }

            }

        }

    }

}

.nav-floating {

    position: absolute;
    display: inline-block;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 35px;
    z-index: 4;

    @include screen-lg {

        top: 150px;
        bottom: initial;
        padding-top: 0;
        z-index: 2;

    }

    .nav-floating-button {
        
        position: absolute;
        top: 35px;
        left: 0;
        text-align: center;
        padding: 5px 15px 5px 15px;
        background: rgba(255, 255, 255, 0.7);
        cursor: pointer;

        @include screen-lg {

            top: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);

        }

        .icon {

            width: 30px;
            height: 30px;
            margin-bottom: -10px;

            @include screen-lg {

                width: 50px;
                height: 50px;

            }

        }

        span {

            display: block;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;
            color: $aluval-blue;

            @include screen-lg {

                font-size: 18px;

            }

        }
        
    }

    .nav-floating-items {

        position: fixed;
        top: 0;
        left: 0;
        width: 240px;
        height: 100%;
        padding-top: 35px;
        margin-left: -100%;
        background: $aluval-white;
        border-right: 2px solid $aluval-brown;

        @include screen-lg {

            position: relative;
            display: block;
            width: 100%;
            height: auto;
            margin: -60px 0 0 0;
            padding: 0;

        }

        @include screen-xl {

            margin: -110px 0 0 0;

        }

        ul {

            width: 100%;
            padding: 0;
            list-style: none;

            @include screen-lg {

                height: 60px;

            }

            @include screen-xl {

                height: 110px;

            }


            li {

                text-align: left;

                @include screen-lg {

                    display: inline-block;
                    width: 16.66%;
                    float: left;
                    text-align: center;

                }

                a {

                    font-size: 18px;
                    line-height: 60px;
                    text-transform: uppercase;
                    text-decoration: none;
                    color: $aluval-brown;

                    .icon-slash {

                        display: none;
                        width: 37px;
                        height: 30px;
                        top: 5px;

                    }

                    @include screen-lg {

                        .icon-slash {

                            width: 37px;
                            height: 30px;
                            top: 5px;

                        }

                    }

                    @include screen-xl {

                        font-size: 20px;
                        line-height: 110px;

                    }

                }

                &.active {

                    a {

                        font-weight: 900;

                        .icon-slash {

                            display: inline-block;

                        }

                    }
                }

            }

        }

    }

}
.news-header {

    display: inline-block;
    width: 100%;
    margin-bottom: 30px;

    .news-header-bg {

        height: 270px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

    }

    .news-header-description-holder {

        text-align: center;

        .news-header-menu {

            width: 100%;
            float: left;
            margin-top: -63px;
            text-align: left;

            @include screen-lg {

                width: 35%;

            }

            @include screen-fullhd {

                margin-top: -77px;

            }

            ul {

                list-style: none;
                padding: 0;
                margin: 0;

                li {

                    padding: 0;
                    margin: 0;
                    text-transform: uppercase;
                    color: $aluval-black-light;

                    &.title {

                        font-family: 'Maax', sans-serif;
                        font-size: 36px;
                        font-weight: 300;
                        line-height: 40px;
                        color: #fff;
                        padding: 7px 30px 0 30px;
                        background: $aluval-brown;
                        border-bottom: 2px solid $aluval-white;

                        @include screen-fullhd {

                            font-size: 50px;
                            line-height: 55px;

                        }

                    }

                    &.active {

                        background: $aluval-blue;

                        a {

                            color: #fff;
                            font-family: 'MaaxBold', sans-serif;

                        }

                    }

                    a {

                        display: block;
                        text-decoration: none;
                        color: $aluval-black-light;
                        font-size: 20px;
                        text-transform: uppercase;
                        padding: 5px 30px 0 30px;
                        border-bottom: 2px solid $aluval-blue;

                        @include screen-md {

                            font-size: 24px;

                        }

                        span {

                        }

                    }

                }

            }

        }

    }

}
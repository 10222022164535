.industrial-about-us {

    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;

    .industrial-about-us-description {

        margin: 0 0 20px 0;

        @include screen-md {

            margin: 50px 0 80px 0;

        }

        .icon-aluval {

            width: 70px;
            height: 70px;
            margin-right: 20px;
            margin-top: -5px;
            background-position: top center;
            float: left;

            @include screen-md {

                width: 110px;
                height: 110px;
                margin-right: 10px;
                margin-top: -10px;

            }

            @include screen-fullhd {

                width: 160px;
                height: 160px;
                margin-right: 20px;

            }

        }

        p {

            color: $aluval-black-grey;
            font-size: 14px;
            font-weight: 300;
            text-align: justify;

            @include screen-md {

                font-size: 18px;

            }

        }

    }

    .industrial-about-us-images {

        margin: 0 -10px;

        .industrial-about-us-images-item {

            display: inline-block;
            height: 280px;
            width: 100%;
            float: left;
            padding: 0 10px;
            margin-bottom: 30px;

            @include screen-md {

                height: 480px;

            }

            @include screen-lg {

                height: 730px;

            }

            &.top-left,
            &.top-right {

                width: 100%;
                height: 280px;

                @include screen-md {

                    height: 480px;

                }

                @include screen-lg {

                    width: 50%;
                    height: 480px;

                }

            }

            .industrial-about-us-images-item-image {

                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

            }

        }

    }

}
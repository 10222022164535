.ce-content {

    margin-bottom: 50px;

    .ce-content-holder {

        text-align: center;

        .ce-content-header {

            height: 320px;
            margin: 30px 0 30px 0;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            .ce-content-header-label {

                position: absolute;
                top: 0;
                right: 0;
                width: 80px;
                height: 80px;
                background-color: $aluval-white;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;

            }

            @include screen-md {

                height: 500px;

                .ce-content-header-label {

                    width: 160px;
                    height: 160px;

                }

            }

        }

        .ce-content-body {

            text-align: left;

            p,
            a {

                display: block;
                text-decoration: none;
                font-size: 14px;
                color: $aluval-black-grey;
                padding: 5px 0;
                margin: 0;

                .icon {

                    width: 32px;
                    height: 32px;
                    top: 10px;

                }

                @include screen-md {

                    font-size: 18px;

                }

            }

            a {

                display: inline-block;
                color: $aluval-blue;

            }

        }

    }

}
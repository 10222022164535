.locations-warehouses {

    display: inline-block;
    width: 100%;
    padding: 50px 0 50px 0;
    background-image: url('../img/bg-section.png');
    background-repeat: repeat;
    margin-bottom: 40px;

    .locations-warehouses-holder {

        display: table;
        width: 100%;
        padding: 20px 10px;
        background: $aluval-chronology-bg;

        .locations-warehouse-sidebar {

            display: block;
            width: 100%;
            height: 980px;
            background-image: url('../img/static/locations/store-bg-left.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @include screen-lg {

                display: table-cell;
                width: 420px;
                height: 900px;
                background-size: contain;

            }

            ul {

                list-style: none;
                padding: 20px 30px;

                li {

                    a {

                        display: block;
                        color: $aluval-blue;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-size: 16px;
                        padding: 2px 0;

                        &:hover {

                            color: $aluval-brown;

                        }

                    }

                    &.active {

                        a {

                            color: $aluval-brown;
                            font-family: MaaxBold, sans-serif;

                        }

                    }

                }

            }

            .locations-warehouse-sidebar-details {

                position: absolute;
                display: none;
                bottom: 0;
                right: 0;
                left: 0;
                padding: 0 30px;

                &.active {

                    display: block;

                }

                .locations-warehouse-sidebar-detail {

                    display: none;

                    img {

                        width: 100%;
                        height: auto;

                        &.no-img {

                            max-height: 125px;
                            max-width: 125px;

                        }

                    }

                    h3 {

                        text-transform: uppercase;
                        font-size: 18px;
                        font-family: MaaxBold, sans-serif;
                        color: $aluval-brown;
                        margin: 10px 0 5px 0;

                    }

                    a {

                        text-decoration: none;
                        color: $aluval-black-light;
                        font-family: MaaxBold, sans-serif;

                    }

                    .map {

                        a {

                            color: $aluval-blue;
                            font-family: Maax, sans-serif;
                            float: right;

                        }

                    }

                    &.active {

                        display: block;

                    }

                }

            }

            .locations-warehouse-sidebar-unselected {

                position: absolute;
                display: none;
                bottom: 0;
                right: 0;
                left: 0;

                .icon-aluval {

                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    width: 150px;
                    height: 150px;

                }

            }


        }

        .locations-warehouse-content {

            display: none;
            background-image: url('../img/static/locations/store-bg-right.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @include screen-md {

                display: table-cell;

            }

            .locations-warehouse-content-map {

                position: absolute;
                top: 0;
                right: 20px;
                bottom: 0;
                left: 20px;

                svg {

                    height: 100%;
                    width: 100%;

                    #DELEGACIONES {

                        polygon {

                            &:hover {

                                cursor: pointer;
                                fill: $aluval-brown !important;

                            }

                        }

                    }

                    #INTERNACIONAL {

                        &:hover {

                            cursor: pointer;
                            fill: $aluval-brown !important;

                        }

                    }

                }

            }

            ul {

                position: absolute;
                top: 0;
                right: 20px;
                bottom: 0;
                left: 20px;
                list-style: none;
                padding: 0;
                margin: 0;
                pointer-events: none;

                li {

                    display: none;
                    height: 100%;
                    pointer-events: none;

                    a {

                        position: absolute;
                        top: 2px;
                        left: 2px;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        pointer-events: none;

                    }

                    &.active {

                        display: block;

                    }


                    &#location-picanya {

                        a { background-image: url('../img/static/locations/picanya_01_almacen.svg'); }

                    }

                    &#location-warehouse-picanya {

                        a { background-image: url('../img/static/locations/picanya_01_central.svg'); }

                    }

                    &#location-alaquas {

                        a { background-image: url('../img/static/locations/alaquas_02_fabrica.svg'); }

                    }

                    &#location-guides-alaquas {

                        a { background-image: url('../img/static/locations/alaquas_02_guias.svg'); }

                    }

                    &#location-albacete {

                        a { background-image: url('../img/static/locations/albacete_03.svg'); }

                    }

                    &#location-alicante {

                        a { background-image: url('../img/static/locations/alicante_03.svg'); }

                    }

                    &#location-barcelona {

                        a { background-image: url('../img/static/locations/barcelona_04.svg'); }

                    }

                    &#location-extremadura {

                        a { background-image: url('../img/static/locations/extremadura_05.svg'); }

                    }

                    &#location-jaen {

                        a { background-image: url('../img/static/locations/jaen_06.svg'); }

                    }

                    &#location-leon {

                        a { background-image: url('../img/static/locations/leon_07.svg'); }

                    }

                    &#location-mallorca {

                        a { background-image: url('../img/static/locations/mallorca_08.svg'); }

                    }

                    &#location-murcia {

                        a { background-image: url('../img/static/locations/murcia_09.svg'); }

                    }

                    &#location-salamanca {

                        a { background-image: url('../img/static/locations/salamanca_10.svg'); }

                    }

                    &#location-malaga {

                        a { background-image: url('../img/static/locations/malaga_11.svg'); }

                    }

                    &#location-madrid {

                        a { background-image: url('../img/static/locations/madrid_12.svg'); }

                    }

                    &#location-international {

                        a { background-image: url('../img/static/locations/internacional_11.svg'); }

                    }

                }

            }

            .locations-warehouse-content-map-descriptions {

                position: absolute;
                max-width: 200px;
                top: 60%;
                left: 65%;

                .locations-warehouse-content-map-descriptions-item {

                    display: none;
                    padding: 10px 15px;
                    font-size: 12px;
                    line-height: 14px;
                    color: $aluval-white;
                    background: rgba($aluval-blue-light, 0.8);


                    &.active {

                        display: inline-block;

                    }

                }

            }

        }

    }

}
.products-header {

    display: inline-block;
    width: 100%;

    .products-header-bg {

        height: 580px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;

        .band {

            position: absolute;
            display: none;
            top: 25%;
            height: 120px;
            width: 100%;
            background: $aluval-white;
            opacity: 0.4;
            -webkit-transform: rotate(-51deg);
            -moz-transform: rotate(-51deg);
            -ms-transform: rotate(-51deg);
            -o-transform: rotate(-51deg);
            transform: rotate(-51deg);

            @include screen-lg {

                display: block;

            }

            &.left {

                left: -310px;

                @include screen-lg {

                    left: -290px;

                }

                @include screen-xl {

                    left: -310px;

                }

            }

            &.right {

                right: 145px;

                @include screen-lg {

                    right: 125px;

                }

                @include screen-xl {

                    right: 140px;

                }

            }

        }

    }

    .products-header-description-holder {

        text-align: center;
        background: $aluval-white;

        .products-header-menu {

            width: 100%;
            float: left;
            margin-top: -193px;
            margin-bottom: 50px;
            text-align: left;
            background: $aluval-white;

            @include screen-md {

                width: 70%;

            }

            @include screen-lg {

                width: 30%;
                margin-top: -260px;

            }

            @include screen-fullhd {

                margin-top: -193px;

            }

            ul {

                list-style: none;
                padding: 0;
                margin: 0;

                li {

                    padding: 0;
                    margin: 0;
                    text-transform: uppercase;
                    color: $aluval-black-light;

                    &.title {

                        font-family: 'Maax', sans-serif;
                        font-size: 36px;
                        font-weight: 300;
                        line-height: 55px;
                        color: #fff;
                        padding: 7px 30px 0 30px;
                        background: $aluval-brown;
                        border-bottom: 2px solid $aluval-white;

                        @include screen-fullhd {

                            font-size: 40px;

                        }

                    }

                    &.active {

                        background: $aluval-blue;

                        a {

                            color: #fff;
                            font-size: 16px;

                            @include screen-fullhd {

                                font-size: 18px;

                            }

                        }

                    }

                    a {

                        display: block;
                        text-decoration: none;
                        color: $aluval-black-light;
                        font-size: 16px;
                        text-transform: uppercase;
                        padding: 5px 30px 0 30px;
                        border-bottom: 2px solid $aluval-blue;

                        @include screen-fullhd {

                            font-size: 18px;

                        }

                        span {

                        }

                    }

                }

            }

        }

        .products-header-title {

            width: 100%;
            float: left;
            text-align: left;

            @include screen-lg {

                width: 70%;

            }

            h1 {


                font-size: 36px;
                text-transform: uppercase;
                padding: 0 0 20px 0;
                color: $aluval-brown;
                line-height: 30px;

                @include screen-md {

                    padding: 40px 160px 0 80px;

                }

                @include screen-fullhd {

                    font-size: 50px;
                    padding: 80px 0 20px 80px;

                }

                .icon-slash {

                    width: 40px;
                    height: 40px;
                    top: 10px;

                    @include screen-fullhd {

                        width: 80px;
                        height: 80px;
                        top: 20px;

                    }

                }

            }

        }

        .products-header-download {

            display: inline-block;
            padding: 20px 0;
            background: $aluval-white;

            @include screen-md {

                margin-top: -385px;
                float: right;

            }

            @include screen-lg {

                margin-top: -180px;

            }

            @include screen-fullhd {

                margin-top: -280px;

            }

            a {

                display: inline-block;
                text-decoration: none;
                color: $aluval-blue;
                text-align: center;

                .icon-holder {

                    width: 70px;
                    height: 70px;
                    display: inline-block;
                    background: $aluval-blue;
                    margin-bottom: 10px;

                    .icon {

                        width: 70px;
                        height: 70px;

                    }

                }

                .text {

                    display: block;
                    width: 130px;
                    text-transform: uppercase;
                    text-align: center;

                }

            }

        }

    }

}
.home-tools {

    padding: 25px 0;

    background-image: url('../img/bg-section.png');
    background-repeat: repeat;

    .home-tools-download {

        width: 100%;
        margin-bottom: 20px;

        @include screen-lg {

            margin-bottom: 0;

        }

        .home-tools-download-image {

            height: 430px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @include screen-lg {

                height: 275px;

            }

            @include screen-xl {

                height: 365px;

            }

            @include screen-xxl {

                height: 430px;

            }

        }

        .home-tools-download-button {

            .home-tools-btn {

                display: inline-block;
                width: 100%;
                height: 75px;
                margin-top: 10px;
                background: $aluval-blue;
                padding: 15px 120px 5px 20px;
                text-decoration: none;

                @include screen-lg {

                    height: 90px;

                }

                span {

                    font-family: 'MaaxMedium', sans-serif;
                    text-transform: uppercase;
                    color: $aluval-white;
                    font-size: 16px;
                    line-height: 16px;

                    @include screen-lg {

                        font-size: 26px;
                        line-height: 32px;

                    }

                    @include screen-xl {

                        font-size: 24px;
                        line-height: 28px;

                    }

                }

                .icon-download {

                    position: absolute;
                    right: 10px;
                    bottom: 0;
                    width: 90px;
                    height: 90px;

                    @include screen-sm {

                        width: 60px;
                        height: 60px;
                        bottom: 5px;

                    }

                }

            }

        }

    }

    .home-tools-video-holder {

        height: 165px;
        padding: 10px 10px;
        background: $aluval-white;

        @include screen-lg {

            height: 375px;

        }

        @include screen-xl {

            height: 465px;

        }

        @include screen-xxl {

            height: 530px;

        }

        .home-tools-video-inner {

            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

        }

    }

}
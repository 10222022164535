.winperfil-content {

    padding: 30px 0 30px 0;

    .winperfil-content-holder {

        & > .container {

            @include screen-lg {

                max-width: 1200px;

            }

        }

        padding: 30px 0 30px 0;
        background: $aluval-chronology-bg;
        text-align: center;

        .winperfil-content-inner {

            .winperfil-content-header {

                margin: 0 0 20px 0;
                background: $aluval-green;

                h1 {

                    font-size: 26px;
                    text-transform: uppercase;
                    text-align: center;
                    padding: 10px 0 3px 0;
                    margin: 0;
                    color: $aluval-white;

                }

            }

            .winperfil-content-features {

                margin: 0 0 20px 0;

                img {

                    width: 100%;
                    height: auto;

                }

            }

            .winperfil-content-description {

                p {

                    font-size: 14px;
                    text-align: justify;
                    color: $aluval-black-grey;

                    @include screen-md {

                        font-size: 18px;

                    }

                    .text-blue {

                        color: $aluval-blue;

                    }

                    .text-winperfil {

                        font-family: 'MaaxBold', sans-serif;
                        color: $aluval-green;

                    }

                    strong {

                    }

                    a {

                        font-family: 'MaaxBold', sans-serif;
                        text-decoration: none;
                        color: $aluval-green;

                    }

                }

            }

        }

    }

}
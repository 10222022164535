.register-form {

    margin: 40px 0 0 0;

    .register-form-holder {

        text-align: center;

        form {

            margin: 0 -5px 0 -5px;

        }

        .register-form-block {

            display: inline-block;
            width: 100%;
            float: left;
            margin: 0 0 40px 0;

            .register-form-block-header {

                padding: 0 5px 0 5px;
                text-align: left;

                h3 {

                    font-size: 20px;
                    text-transform: uppercase;
                    color: $aluval-white;
                    padding: 5px 15px 3px 15px;
                    background: $aluval-blue;

                }

            }

            .register-form-block-item {

                display: inline-block;
                width: 100%;
                padding: 0 5px 0 5px;
                margin: 0 0 5px 0;

                @include screen-md {

                    width: 50%;
                    float: left;

                }

                p,
                span,
                label {

                    text-align: left;
                    color: $aluval-blue-dark;

                }

                p {

                    text-align: justify;

                }

                a {

                    text-decoration: underline;
                    color: $aluval-blue-dark;

                }

                input[type='text'],
                input[type='password'],
                .select-placeholder {

                    display: block;
                    /*height: 64px;*/
                    width: 100%;
                    background: $aluval-grey;
                    border: none;
                    margin-bottom: 5px;
                    padding: 7px 15px;
                    font-size: 16px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                    @include screen-xxl {

                        padding: 10px 15px;

                    }

                    &::placeholder {

                        opacity: 1;
                        color: $aluval-placeholder;
                        text-transform: uppercase;
                        font-size: 16px;

                    }

                    &:-ms-input-placeholder,
                    &::-ms-input-placeholder {

                        opacity: 1;
                        color: $aluval-placeholder;
                        text-transform: uppercase;
                        font-size: 16px;

                    }

                    a {

                        text-decoration: none;

                    }

                }

                .select-placeholder {

                    color: $aluval-placeholder;
                    text-transform: uppercase;
                    text-align: left;
                    padding: 7px 15px;
                    /*line-height: 68px;*/
                    font-size: 16px;
                    cursor: pointer;

                    @include screen-xxl {

                        padding: 10px 15px;

                    }

                    .icon-arrow-right {

                        height: 12px;
                        width: 12px;

                    }

                }

                select {

                    display: none;

                }

                .btn {

                    color: $aluval-white;
                    text-transform: uppercase;
                    font-size: 16px;
                    padding: 5px 30px 3px 30px;
                    border-radius: 0;
                    background: $aluval-blue-dark;

                }

                &.recaptcha {

                    .g-recaptcha {

                        display: inline-block;
                        margin: 20px 0 20px 0;

                    }

                }

            }

            &.personal {

                .register-form-block-header {

                    width: 100%;

                    @include screen-md {

                        width: 50%;

                    }

                }

            }

            &.login,
            &.other {

                width: 100%;

                @include screen-md {

                    width: 50%;

                }

                .register-form-block-item {

                    width: 100%;

                }

            }

            &.conditions {

                .register-form-block-item {

                    width: 100%;
                    margin: 10px 0 10px 0;
                    text-align: left;

                    &.recaptcha,
                    &.submit {

                        text-align: center;

                    }

                }

            }

            &.legal {

                .register-form-block-item {

                    width: 100%;

                }

            }

        }

    }

}
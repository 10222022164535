.company-header {

    display: inline-block;
    width: 100%;

    .company-header-bg {

        height: 330px;
        background-size: cover;
        background-position: center 90%;
        background-repeat: no-repeat;

    }

    .company-header-description-holder {

        .company-header-menu {

            width: 100%;
            float: left;
            margin-top: -63px;

            @include screen-md {

                width: 35%;

            }

            ul {

                list-style: none;
                padding: 0;
                margin: 0;

                li {

                    padding: 0;
                    margin: 0;
                    text-transform: uppercase;
                    color: $aluval-black-light;

                    &.title {

                        font-family: 'Maax', sans-serif;
                        font-size: 36px;
                        font-weight: 300;
                        line-height: 55px;
                        color: $aluval-white;
                        padding: 7px 30px 0 30px;
                        background: $aluval-brown;
                        border-bottom: 2px solid $aluval-white;

                        @include screen-fullhd {

                            font-size: 40px;

                        }

                    }

                    &.active {

                        background: $aluval-blue;

                        a {

                            color: $aluval-white;
                            font-size: 16px;

                            @include screen-fullhd {

                                font-size: 18px;

                            }

                        }

                    }

                    a {

                        display: block;
                        text-decoration: none;
                        color: $aluval-black-light;
                        font-size: 16px;
                        text-transform: uppercase;
                        padding: 5px 30px 0 30px;
                        border-bottom: 2px solid $aluval-blue;

                        @include screen-fullhd {

                            font-size: 18px;

                        }

                        .icon-arrow-down {

                            margin-bottom: -10px;
                            top: -4px;

                        }

                    }

                }

            }

        }

        .company-header-description-short {

            width: 100%;
            float: left;
            margin: 30px 0 0 0;

            @include screen-md {

                width: 65%;
                margin: 70px 0 0 0;
                font-size: 18px;

            }

            p {

                font-size: 18px;
                text-align: justify;
                color: $aluval-black-grey;
                padding: 40px 0 0 0;

                strong {
                    color: $aluval-blue;
                }

            }

            .icon-aluval {

                float: left;
                height: 95px;
                width: 95px;
                margin: 35px 20px 0 20px;

                @include screen-lg {

                    margin: 0 20px;

                }

            }

        }

        .company-header-description-large {

            width: 100%;
            float: left;
            margin: 10px 0 50px 0;

            p {

                font-size: 18px;
                text-align: justify;
                color: $aluval-black-grey;
                margin: 0;

                strong {

                    color: $aluval-blue;

                }

            }

            @include screen-md {

                margin: 30px 0 50px 0;

            }

        }

    }

    .company-header-building {

        display: inline-block;
        width: 100%;
        padding: 50px 0 50px 0;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        .company-header-building-holder {

            margin: 0 -10px;

            .company-header-building-img-holder {

                display: inline-block;
                height: 350px;
                float: left;
                padding: 0 10px;
                margin-bottom: 20px;

                &.left {

                    width: 100%;

                    @include screen-md {

                        width: 40%;

                    }

                }

                &.right {

                    width: 100%;

                    @include screen-md {

                        width: 60%;

                    }

                }

                .company-header-building-img {
                    height: 100%;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }

            }

        }

    }

}
.quality-content {

    padding: 50px 0;

    .quality-content-holder {

        text-align: center;

        .quality-content-header {

            height: 240px;
            margin-bottom: 30px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @include screen-lg {

                height: 350px;
                background-position: center -100px;

            }

        }

        .quality-content-block {

            margin-bottom: 30px;

            & + .quality-content-block {

                padding-top: 30px;
                border-top: 1px solid $aluval-blue;

            }

            h2 {

                font-size: 20px;
                text-transform: uppercase;
                color: $aluval-brown;
                margin: 0 0 30px 0;
                text-align: center;

                &.left {

                    text-align: left;

                }

                @include screen-lg {

                    font-size: 28px;

                }

            }

            .quality-content-block-cols {

                display: table;
                width: 100%;

                &.only-images {

                    height: 300px;

                }

                .quality-content-block-col {

                    display: table-cell;
                    width: 100%;

                    &.col-33 {

                        width: 100%;

                        @include screen-md {

                            width: 33.33%;

                        }

                    }

                    &.col-66 {

                        width: 100%;

                        @include screen-md {

                            width: 66.66%;

                        }

                    }

                    &.col-50 {

                        width: 100%;

                        @include screen-md {

                            width: 50%;

                        }

                    }

                    .icon-aluval {

                        width: 120px;
                        height: 120px;
                        float: left;
                        margin-bottom: -10px;
                        margin-left: -10px;

                    }

                }

            }

            .quality-content-block-img {

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

            }

            .quality-content-block-text {

                p {

                    font-size: 14px;
                    color: $aluval-black-grey;
                    text-align: justify;
                    margin: 0;
                    padding: 0;

                    strong {

                        color: $aluval-blue;
                        font-family: MaaxBold, sans-serif;

                        &.black {

                            color: $aluval-black-grey;

                        }

                    }

                    & + p {

                        margin-top: 20px;

                    }

                    @include screen-md {

                        font-size: 18px;

                    }

                }

            }

            .quality-content-block-list {

                .quality-content-block-list-item {

                    display: inline-block;
                    width: 33.33%;
                    float: left;
                    margin: 0;
                    padding: 0;
                    text-align: center;

                    img {

                        display: inline-block;
                        height: 75px;
                        width: auto;
                        max-width: 200px;

                        @include screen-md {

                            height: auto;
                            max-height: 120px;
                            width: 100%;

                        }

                    }

                    a {

                        display: block;
                        text-align: center;
                        text-decoration: none;


                        .icon {

                            width: 24px;
                            height: 24px;
                            top: 10px;

                            @include screen-md {

                                width: 36px;
                                height: 36px;

                            }

                        }

                        span {

                            font-size: 12px;
                            color: $aluval-black-grey;

                            @include screen-md {

                                font-size: 16px;

                            }

                        }

                    }

                }

            }

            .quality-content-block-list-vertical {

                border-top: 1px solid $aluval-blue;
                border-bottom: 1px solid $aluval-blue;

                .quality-content-block-list-item {

                    &.light {

                        background: rgba($aluval-blue, 0.05);

                    }

                    &.dark {

                        background: rgba($aluval-blue, 0.2);

                    }

                    & + .quality-content-block-list-item {

                        border-top: 1px solid $aluval-blue;

                    }

                    p {

                        margin: 0;
                        padding: 10px 40px;
                        text-align: left;
                        font-size: 14px;
                        color: $aluval-black-grey;

                        .number {

                            font-family: MaaxBold, sans-serif;
                            margin-left: -20px;
                            margin-right: 5px;

                        }

                        strong {

                            font-family: MaaxBold, sans-serif;

                        }

                        @include screen-md {

                            font-size: 18px;

                        }

                    }

                }

            }

            .quality-content-block-two-img {

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                overflow: hidden;

                .quality-content-block-img-50 {

                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 30px;
                    height: 50%;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    &.top {

                        bottom: initial;
                        margin-top: -10px;

                    }

                    &.bottom {

                        top: initial;
                        margin-bottom: -10px;

                    }

                }

            }

        }

    }

}
.lab-tabs {

    text-align: center;

    .lab-tabs-holder {

        .lab-tabs-selector {

            margin: 20px 0;
            padding: 5px 0 0 0;
            border-top: 1px solid $aluval-blue;
            border-bottom: 1px solid $aluval-blue;

            ul {

                display: inline-block;
                width: 100%;
                padding: 0;
                margin: 0;
                list-style: none;

                li {

                    display: inline-block;
                    width: 100%;
                    float: left;
                    padding: 5px 5px;

                    @include screen-md {

                        width: 33.33%;

                    }

                    @include screen-xl {

                        width: 25%;

                    }

                    a {

                        display: table;
                        width: 100%;
                        height: 70px;
                        vertical-align: middle;
                        text-decoration: none;
                        background: $aluval-grey;
                        padding: 5px 5px;

                        span {

                            display: table-cell;
                            vertical-align: middle;
                            padding: 0 40px;
                            font-size: 16px;
                            font-family: MaaxBold, sans-serif;
                            text-align: center;
                            text-transform: uppercase;
                            color: $aluval-blue;

                        }

                    }

                    &.active {

                        a {

                            background: none;

                            span {

                                color: $aluval-brown;

                            }

                        }

                    }

                }

            }

        }

        .lab-tabs-content {

            .lab-tabs-item {

                display: none;

                &.active {

                    display: block;

                }

                .lab-tabs-item-tabs {

                    margin-bottom: 30px;

                    ul {

                        display: inline-block;
                        width: 100%;
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        border: 1px solid $aluval-blue;

                        li {

                            display: inline-block;
                            width: 100%;
                            float: left;

                            @include screen-md {

                                width: 16.66%;

                            }

                            a {

                                display: block;
                                text-align: center;
                                text-decoration: none;
                                text-transform: uppercase;
                                color: $aluval-brown;
                                font-size: 16px;
                                padding: 8px 0 5px 0;

                            }

                            & + li {

                                border-top: 1px solid $aluval-blue;

                                @include screen-md {

                                    border-top: none;
                                    border-left: 1px solid $aluval-blue;

                                }

                            }

                            &.active {

                                a {

                                    font-family: MaaxBold, sans-serif;

                                }

                            }

                        }

                    }

                }

                .lab-tabs-item-content {

                    text-align: left;

                    .lab-tabs-item-content-item {

                        display: none;
                        width: 100%;
                        margin-bottom: 30px;

                        &.active {

                            display: inline-block;

                        }

                        .title {

                            display: inline-block;
                            text-transform: uppercase;
                            font-size: 18px;
                            color: $aluval-white;
                            padding: 5px 15px 2px 15px;
                            background: $aluval-brown;

                            @include screen-md {

                                font-size: 20px;

                            }

                        }

                        .lab-tabs-item-content-item-cols {

                            margin: 0 -15px;

                            padding: 20px 0 0 0;

                            .lab-tabs-item-content-item-col {

                                display: inline-block;
                                width: 100%;
                                float: left;
                                padding: 5px 15px;

                                &.col-50 {

                                    width: 100%;

                                    &:nth-child(even) {
                                        float: right;
                                    }

                                    @include screen-md {

                                        width: 50%;

                                    }

                                }

                                .lab-tabs-item-content-item-block {

                                    display: inline-block;
                                    width: 100%;
                                    padding: 15px 0;
                                    border-top: 1px solid $aluval-blue;
                                    //margin-bottom: 30px;

                                    h5 {

                                        text-transform: uppercase;
                                        font-size: 16px;
                                        font-family: MaaxBold, sans-serif;
                                        color: $aluval-brown;

                                        @include screen-md {

                                            font-size: 18px;

                                        }

                                    }

                                    .lab-tabs-item-content-item-list {

                                        border-top: 1px solid $aluval-blue;
                                        border-bottom: 1px solid $aluval-blue;

                                        .lab-tabs-item-content-item-row {

                                            display: inline-block;
                                            width: 100%;
                                            padding: 10px 15px 10px 15px;
                                            margin: 0;

                                            span {

                                                font-size: 14px;
                                                color: $aluval-black-grey;

                                                &.right {

                                                    float: right;

                                                }

                                                &.small {

                                                    font-size: 12px;

                                                }

                                                @include screen-md {

                                                    font-size: 18px;

                                                    &.small {

                                                        font-size: 14px;

                                                    }

                                                }

                                            }

                                            &.header {

                                                background: rgba($aluval-blue, 0.2);
                                                font-family: MaaxBold, sans-serif;

                                            }

                                            &.light {

                                                background: rgba($aluval-blue, 0.05);

                                            }

                                            &.dark {

                                                background: rgba($aluval-blue, 0.2);

                                            }

                                            & + .lab-tabs-item-content-item-row {

                                                border-top: 1px solid $aluval-blue;

                                            }

                                            &.row-50 {

                                                display: inline-block;
                                                width: 100%;
                                                float: left;

                                                @include screen-md {

                                                    width: 50%;

                                                }

                                            }

                                            .item-link {

                                                display: inline-block;
                                                width: 100%;
                                                text-decoration: none;
                                                padding: 5px 10px 5px 10px;
                                                border-top: 1px solid $aluval-blue;
                                                border-bottom: 1px solid $aluval-blue;

                                                & + .item-link {

                                                    border-top: none;

                                                }

                                                span {

                                                    font-size: 14px;
                                                    color: $aluval-black-grey;

                                                    @include screen-md {

                                                        font-size: 18px;

                                                    }

                                                }

                                                .icon {

                                                    width: 32px;
                                                    height: 32px;
                                                    top: 10px;

                                                }

                                            }

                                        }

                                        &.border-none {

                                            border: none;

                                            .lab-tabs-item-content-item-row {

                                                & + .lab-tabs-item-content-item-row {

                                                    border: none;

                                                }

                                            }

                                        }

                                    }

                                    &.border-none {

                                        border: none;

                                    }

                                    & > p,
                                    & > h5 {

                                        display: inline-block;
                                        width: 100%;
                                        text-transform: uppercase;
                                        font-size: 16px;
                                        font-family: MaaxBold, sans-serif;
                                        color: $aluval-brown;
                                        padding: 0 0 10px 0;
                                        //border-top: 1px solid $aluval-blue;
                                        margin: 0;

                                        @include screen-md {

                                            font-size: 18px;

                                        }

                                    }

                                    & > table {

                                        width: 100%;
                                        border-bottom: 1px solid $aluval-blue;
                                        margin: 0 0 20px 0;

                                        tr {

                                            border-top: 1px solid $aluval-blue;
                                            background: rgba($aluval-blue, 0.2);

                                            &:nth-of-type(odd) {

                                                background: rgba($aluval-blue, 0.05);

                                            }

                                            &:last-child {

                                                background: none;

                                            }

                                            td {

                                                font-size: 14px;
                                                color: $aluval-black-grey;
                                                padding: 10px 15px 10px 15px;

                                                @include screen-md {

                                                    font-size: 18px;

                                                }

                                            }

                                        }
                                    }

                                }

                                &.separator {

                                    display: none;
                                    padding: 0 15px;
                                    margin: 0;

                                    @include screen-md {

                                        display: block;
                                        margin: -66px 0 0 0;

                                    }

                                    .border-separator-blue {

                                        height: 1px;
                                        background: $aluval-blue;

                                    }

                                    .icon-aluval {


                                        @include screen-md {

                                            position: absolute;
                                            top: -107px;
                                            right: 15px;
                                            width: 120px;
                                            height: 120px;
                                            background-color: $aluval-white;

                                        }

                                    }

                                    & + .lab-tabs-item-content-item-col {

                                        @include screen-md {

                                            margin-top: -70px;

                                        }

                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

    }

}
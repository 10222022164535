.products-catalogues {

    .products-catalogues-holder {

        text-align: center;

        .products-catalogues-tabs {

            margin: 0 -5px;

            ul {

                list-style: none;
                margin: 0;
                padding: 0;

                li {

                    display: inline-block;
                    width: 100%;
                    float: left;
                    margin: 0 0 10px 0;
                    padding: 0 5px;

                    @include screen-md {

                        width: 50%;

                    }

                    @include screen-xl {

                        width: 33.33%;

                    }

                    @include screen-xxl {

                        width: 25%;
                    }

                    a {

                        display: block;
                        font-size: 16px;
                        text-transform: uppercase;
                        color: $aluval-white;
                        padding: 10px 0 5px 0;
                        background: $aluval-blue;

                    }

                    &.active {

                        a {

                            background: $aluval-ocre;

                        }

                    }

                }

            }

        }

    }

}
.planters-header {

    display: inline-block;
    width: 100%;

    .planters-header-bg {

        height: 530px;
        background-size: 100% auto;
        background-position: center top;
        background-repeat: no-repeat;

        @include screen-md {

            height: 630px;
            background-size: cover;
            background-position: center bottom;

        }

        @include screen-lg {

            height: 350px;

        }

        @include screen-xl {

            height: 470px;

        }

        @include screen-fullhd {

            height: 630px;

        }

    }

    .planters-header-description-holder {

        text-align: center;

        .planters-header-menu {

            width: 100%;
            float: left;
            margin-top: -47px;
            text-align: left;
            z-index: 9;

            @include screen-lg {

                width: 35%;

            }

            @include screen-fullhd {

                margin-top: -62px;

            }

            ul {

                list-style: none;
                padding: 0;
                margin: 0;

                li {

                    padding: 0;
                    margin: 0;
                    text-transform: uppercase;
                    color: $aluval-black-light;

                    &.title {

                        font-family: 'Maax', sans-serif;
                        font-size: 36px;
                        font-weight: 300;
                        line-height: 40px;
                        color: #fff;
                        padding: 7px 30px 0 30px;
                        background: $aluval-blue-dark;
                        border-bottom: 2px solid $aluval-white;

                        @include screen-fullhd {

                            font-size: 50px;
                            line-height: 55px;

                        }

                    }

                    &.active {

                        background: $aluval-blue;

                        a {

                            color: #fff;
                            font-family: 'MaaxBold', sans-serif;

                        }

                    }

                    a {

                        display: block;
                        text-decoration: none;
                        color: $aluval-black-light;
                        font-size: 20px;
                        text-transform: uppercase;
                        padding: 5px 30px 0 30px;
                        border-bottom: 2px solid $aluval-blue;

                        @include screen-md {

                            font-size: 24px;


                        }

                        span {

                        }

                    }

                }

            }

        }

        .planters-header-title {

            margin: 15px 0 70px 0;
            padding: 170px 0 0 0;

            h1 {

                font-size: 18px;
                font-family: Max, sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 22px;
                color: $aluval-blue-dark;
                margin: 0;
                padding: 0;

                @include screen-md {

                    font-size: 28px;
                    line-height: 20px;

                }

                @include screen-lg {

                    font-size: 36px;
                    padding: 40px 0 20px 0;
                    line-height: 45px;

                }

            }

            .icon-slash {

                width: 30px;
                height: 30px;
                top: 10px;

                @include screen-md {

                    width: 50px;
                    height: 50px;
                    top: 15px;

                }

                @include screen-lg {

                    width: 60px;
                    height: 60px;
                    top: 15px;

                }

            }

            @include screen-lg {

                padding: 0;

            }

        }

    }

}
.planters-blocks {

    .planters-blocks-holder {

        text-align: center;

        .planters-blocks-specs {

            .col-left,
            .col-right {

                text-align: center;

                p {

                    font-family: 'Maax', sans-serif;
                    font-weight: 300;
                    text-align: justify;
                    font-size: 16px;
                    color: $aluval-blue-dark;
                    line-height: 28px;

                }

                img {

                    width: 100%;
                    max-width: 350px;
                    height: auto;
                    margin: 0 0 20px 0;

                }

            }

            @include screen-lg {

                display: table;
                width: 70%;
                margin: 0 auto;

                .col-left,
                .col-right {

                    display: table-cell;
                    width: 50%;
                    text-align: left;
                    vertical-align: middle;
                    padding: 0 15px;

                    img {

                        margin: 0;

                    }

                }

                &.right {

                    .col-left {

                        float: right;
                        text-align: right;

                    }

                    .col-right {

                        float: left;
                        padding: 50px 15px;

                        @include screen-xl {

                            padding: 80px 15px;

                        }

                    }

                }

            }

        }

        .planters-blocks-info {

            .btn {

                font-family: Maax, sans-serif;
                font-size: 16px;
                text-transform: uppercase;
                text-decoration: none;
                color: $aluval-white;
                background: $aluval-blue-dark;
                padding: 5px 30px;
                border-radius: 0;

            }
        }

        .planters-blocks-separator {

            display: inline-block;
            width: 100%;
            padding: 10px 0 10px 0;
            margin: 30px 0 30px 0;
            background-image: url('../img/bg-section.png');
            background-repeat: repeat;

            @include screen-md {

                margin: 120px 0 120px 0;

            }

        }

    }

    .planters-blocks-separator {

        display: inline-block;
        width: 100%;
        padding: 10px 0 10px 0;
        margin: 30px 0 30px 0;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        @include screen-md {

            margin: 120px 0 120px 0;

        }

    }

}
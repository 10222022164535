.cte-specifications {

    padding: 30px 0 30px 0;

    .cte-specifications-holder {

        .cte-specifications-content {

            & > p {

                font-size: 14px;
                color: $aluval-black-grey;

            }

            .cte-specifications-content-group {

                display: none;

                &.active {

                    display: block;

                }

                .cte-specifications-content-tabs {

                    ul {

                        width: 100%;
                        display: inline-block;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {

                            display: inline-block;
                            float: left;
                            padding: 5px 20px 5px 20px;
                            background: $aluval-blue;

                            &.active {

                                background: $aluval-brown;

                            }

                            a {

                                font-size: 12px;
                                text-transform: uppercase;
                                text-decoration: none;
                                color: $aluval-white;

                            }

                            & + li {

                                margin: 0 0 0 10px;

                            }

                        }

                    }

                }

                .cte-specifications-content-panes {

                    padding: 20px 0 20px 0;

                    .cte-specifications-content-pane {

                        display: none;

                        &.active {

                            display: block;

                        }

                        table {

                            width: 100%;
                            border-top: 1px solid $aluval-blue;
                            border-bottom: 1px solid $aluval-blue;

                            thead {

                                tr {

                                    td {

                                        color: $aluval-brown;
                                        font-family: MaaxBold, sans-serif;
                                        font-size: 14px;
                                        text-transform: uppercase;
                                        text-align: left;
                                        padding: 10px 10px 7px 10px;
                                        vertical-align: middle;
                                        border: 1px dotted $aluval-blue;

                                        &.series {

                                            text-align: center;

                                        }

                                        &.width-10 {

                                            width: 10%;

                                        }

                                        &.width-20 {

                                            width: 20%;

                                        }

                                        &.width-30 {

                                            width: 30%;

                                        }

                                        &.width-60 {

                                            width: 60%;

                                        }

                                        &:first-child {

                                            border-left: 0;

                                        }

                                        &:last-child {

                                            border-right: 0;

                                        }

                                    }

                                    &:first-child {

                                        td {

                                            border-top: none;

                                        }

                                    }

                                }

                            }

                            tbody {

                                tr {

                                    &:nth-child(odd) {

                                        background: rgba($aluval-blue-2, 0.2);

                                    }

                                    td {

                                        font-size: 14px;
                                        color: $aluval-black-grey;
                                        padding: 10px 10px 10px 10px;
                                        border: 1px dotted $aluval-blue;

                                        &.series {

                                            text-align: center;

                                        }

                                        &:first-child {

                                            border-left: 0;

                                        }

                                        &:last-child {

                                            border-right: 0;

                                        }

                                    }

                                    &:last-child {

                                        td {

                                            border-bottom: none;

                                        }

                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

    }

}
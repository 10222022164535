* {
    position: relative;
}

body {
    font-family: 'Maax', sans-serif;
}


.container {

    @include screen-lg {

        padding: 0 5%;
        max-width: 1440px;

    }

    @include screen-xxl {

        padding: 0 7%;

    }

    @include screen-fullhd {

        padding: 0;

    }
}

.row-center {

    display: inline-block;
    width: 100%;
    margin: 0 auto;

}

.select-placeholder-dropdown {

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    list-style: none;
    padding: 0;
    margin: 2px 0 0 0;
    background: $aluval-white;
    z-index: 9;

    li {

        padding: 2px 0;
        margin: 0;

        a {

            display: block;
            padding: 5px 15px;
            font-size: 16px;
            text-transform: uppercase;
            color: $aluval-black-grey;
            background: $aluval-grey;

        }

    }

}

.transition-ease-in-out {

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

}

.mt-30 {
    margin-top: 30px !important;
}
.mrl--15 {
    margin: 0 -15px !important;
}
.right-50 {
    right: 50px !important;
}
.left-10 {
    left: 10px !important;
}
.pl-30 {
    padding-left: 30px !important;
}
.pl-50 {
    padding-left: 50px !important;
}
.show-up-768 {

    display: none !important;

    @include screen-md {

        display: table-cell !important;

    }

}

.alert-modal {

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: -1;

    -webkit-transition: opacity 0.3s linear, z-index 0.1s linear 0.4s;
    -moz-transition: opacity 0.3s linear, z-index 0.1s linear 0.4s;
    -ms-transition: opacity 0.3s linear, z-index 0.1s linear 0.4s;
    -o-transition: opacity 0.3s linear, z-index 0.1s linear 0.4s;
    transition: opacity 0.3s linear, z-index 0.1s linear 0.4s;

    .alert-modal-overlay {

        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($aluval-black, 0.3);
        pointer-events: none;

    }

    .alert-modal-holder {

        top: 50%;
        left: 50%;
        height: 240px;
        width: 80%;
        max-width: 450px;
        background: $aluval-white;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .alert-modal-header {

            color: $aluval-white;
            text-transform: uppercase;
            padding: 5px 10px 3px 10px;
            font-size: 24px;
            background: $aluval-blue;


        }

        .alert-modal-content {

            font-size: 16px;
            padding: 10px 10px 10px 10px;
            color: $aluval-black-grey;

            p {

                margin: 0;
                padding: 0;

            }

        }

        .alert-modal-actions {

            padding: 10px 10px 10px 10px;
            text-align: right;

            .btn {

                display: inline-block;
                color: $aluval-white;
                font-size: 16px;
                text-transform: uppercase;
                padding: 5px 30px 3px 30px;
                background: $aluval-brown;
                border-radius: 0;

            }

        }

    }

    &.show {

        opacity: 1;
        z-index: 999999;

    }

}

.aluval-back-to-top {

    display: none;
    position: fixed;
    bottom: 20px;
    right: 10px;
    padding: 5px 10px 2px 5px;
    background: $aluval-white;
    border: 5px solid $aluval-blue;
    border-radius: 80px;
    cursor: pointer;
    z-index: 9;

    @include screen-xxl {

        bottom: 20px;
        right: 20px;
        padding: 5px 15px 5px 5px;

    }

    .icon {

        @include screen-xl {

            width: 30px;
            height: 30px;

        }

        @include screen-xxl {

            width: 40px;
            height: 40px;

        }

        @include screen-fullhd {

            width: 60px;
            height: 60px;

        }

    }

    &:hover {

        border-color: $aluval-brown;

    }

    &.showing {

        display: block;

    }

}
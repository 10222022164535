.mobile-apps-content {

    padding: 30px 0 30px 0;

    .mobile-apps-content-holder {

        & > .container {

            @include screen-lg {

                max-width: 1200px;

            }

        }

        text-align: center;
        padding: 30px 0 30px 0;
        background: $aluval-chronology-bg;

        .mobile-apps-content-inner {

            .mobile-apps-content-header {

                margin: 0 0 20px 0;
                background: $aluval-brown;

                h1 {

                    font-size: 26px;
                    text-transform: uppercase;
                    text-align: center;
                    padding: 10px 0 3px 0;
                    margin: 0;
                    color: $aluval-white;

                }

            }

            .mobile-apps-content-description {

                text-align: left;

                h2 {

                    font-size: 24px;
                    text-transform: uppercase;
                    color: $aluval-blue-new;
                    text-align: center;

                }

                p {

                    font-size: 18px;
                    color: $aluval-black-grey;

                }

            }

            .mobile-apps-content-block {

                text-align: left;
                background: $aluval-grey-dark;
                margin: 0 0 20px 0;

                .block-left {

                    padding: 20px 20px 20px 20px;

                    .block-left-inner {

                        padding: 20px 20px 20px 20px;
                        background: $aluval-white;

                        .block-title {

                            position: relative;
                            padding: 25px 0 20px 0;

                            img {

                                position: absolute;
                                top: 0;
                                left: 0;
                                height: auto;
                                width: 90px;

                            }

                            span {

                                display: block;
                                margin-left: 110px;
                                font-size: 14px;
                                font-family: 'MaaxBold', sans-serif;
                                color: $aluval-black-grey;

                                &.title-blue {

                                    text-transform: uppercase;
                                    color: $aluval-blue-new;

                                }

                                &.title-green {

                                    text-transform: uppercase;
                                    color: $aluval-green;

                                }

                            }

                        }

                        .block-content {

                            p {

                                font-size: 14px;
                                color: $aluval-black-grey;
                                text-align: justify;
                                padding: 0;
                                margin: 0 0 5px 0;

                                .text-blue {

                                    font-family: 'MaaxBold', sans-serif;
                                    color: $aluval-blue-new;

                                }

                                .text-green {

                                    font-family: 'MaaxBold', sans-serif;
                                    color: $aluval-green;

                                }

                                strong {

                                    font-family: 'MaaxBold', sans-serif;

                                }

                            }

                        }

                    }

                }

                .block-right {

                    text-align: center;

                    img {

                        width: 100%;
                        max-width: 280px;
                        height: auto;

                    }

                }

            }

            @include screen-md {

                .mobile-apps-content-block {

                    display: table;
                    width: 100%;

                    .block-left {

                        display: table-cell;

                    }

                    .block-right {

                        display: table-cell;
                        width: 305px;
                        vertical-align: middle;
                        text-align: right;

                        img {

                            max-width: none;

                        }

                    }

                }

            }

            @include screen-xxl {

                .mobile-apps-content-block {

                    .block-right {

                        img {

                            max-width: 285px;

                        }

                    }

                }

            }

        }

    }

}
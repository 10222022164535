.home-head-text {

    padding: 50px 0;

    .home-head-text-holder {

        h2 {
            font-family: 'DolceVita', sans-serif;
            font-size: 24px;
            text-align: center;
            color: $aluval-brown;

            @include screen-lg {

                font-size: 32px;

            }

        }

        p {

            font-size: 14px;
            text-align: center;
            color: $aluval-black-grey;
            margin: 20px auto;

            strong {

                color: $aluval-blue;

            }

            @include screen-lg {

                font-size: 20px;

            }

        }

    }

    .home-head-text-search {

        display: block;
        width: 100%;

        @include screen-lg {

            display: table;

        }

        & > .text-holder,
        & > .input-reference,
        & > .input-description,
        & > .btn {

            display: block;

            @include screen-lg {

                display: table-cell;

            }

        }

        .text-holder {

            width: 100%;
            white-space: nowrap;
            vertical-align: middle;
            font-family: 'MaaxMedium', sans-serif;
            color: $aluval-white;
            text-transform: uppercase;
            padding: 5px 20px 2px 50px;
            background: $aluval-blue;

            .icon-search {

                position: absolute;
                left: 0;
                bottom: -10px;
                height: 45px;
                width: 45px;

            }

            @include screen-lg {

                width: 1%;

            }

        }

        .input-reference {

            width: 100%;

            @include screen-lg {

                width: 90px;

            }

            input {

                width: 100%;

                @include screen-lg {

                    width: auto;

                }

            }

        }

        .input-description {

            width: 100%;

            input {

                width: 100%;
                margin-left: 0;
                margin-top: -1px;

                @include screen-lg {

                    margin-left: -1px;

                }

            }

        }

        .input-reference,
        .input-description {

            input {

                height: 38px;
                border: 1px solid $aluval-placeholder;
                padding: 0 15px;

                &::placeholder,
                &:-ms-input-placeholder,
                &::-ms-input-placeholder {

                    opacity: 1;
                    color: $aluval-placeholder;

                }

            }

        }

        .button-holder {

            .btn {

                width: 100%;
                font-family: 'MaaxMedium', sans-serif;
                height: 38px;
                text-transform: uppercase;
                color: $aluval-white;
                line-height: 28px;
                background: $aluval-brown;
                border-radius: 0;
                margin-left: 0;

                @include screen-lg {

                    width: auto;
                    margin-left: -1px;

                }

            }

        }

    }

}
.parkingdoor-wireless {

    .parkingdoor-wireless-holder {

        text-align: center;

        .parkingdoor-wireless-title {

            margin: 15px 0 70px 0;

            h2 {

                font-size: 18px;
                font-family: Max, sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 22px;
                color: $aluval-blue-dark;
                margin: 0;
                padding: 0;

                @include screen-md {

                    font-size: 28px;
                    line-height: 20px;

                }

                @include screen-lg {

                    font-size: 36px;
                    padding: 40px 0 20px 0;
                    line-height: 45px;

                }

                @include screen-fullhd{

                    font-size: 70px;
                    line-height: 80px;

                }

            }

            .icon-slash-orange {

                width: 30px;
                height: 30px;
                top: 5px;

                @include screen-md {

                    width: 50px;
                    height: 50px;
                    top: 15px;

                }

                @include screen-lg {

                    width: 60px;
                    height: 60px;
                    top: 15px;

                }

            }


        }

        .parkingdoor-wireless-description {

            p {

                font-family: Maax, sans-serif;
                font-weight: 300;
                font-size: 16px;
                line-height: 22px;
                text-align: justify;
                color: $aluval-blue-dark;
                max-width: 730px;
                margin: 0 auto;

            }

        }

        .parkingdoor-wireless-content {

            display: inline-block;
            width: 100%;
            padding: 50px 0 50px 0;
            text-align:center;
            max-width: 730px;
            margin: 0 auto;

            .col-left,
            .col-right {

                padding: 0;
                margin: 0 0 40px 0;

                img {

                    width: 100%;
                    height: auto;

                }

                p {

                    font-family: Maax, sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                    text-align: justify;
                    padding: 0;
                    margin: 0 0 30px 0;
                    color: $aluval-blue-dark;
                    line-height: 26px;

                    strong {

                        font-weight: 900;

                    }

                }

                .icon-parkingdoor-press-button {

                    width: 130px;
                    height: 90px;
                    margin-bottom: 30px;

                }

                .icon-parkingdoor-earnings {

                    width: 85px;
                    height: 85px;
                    margin-bottom: 35px;

                }

            }

            @include screen-lg {

                .col-left,
                .col-right {

                    display: inline-block;
                    width: 50%;
                    float: left;
                    padding: 0 20px;
                    margin: 0;

                }

            }

        }

        .parkingdoor-wireless-content-garage {

            padding: 0 0 50px 0;

            .icon {

                width: 300px;
                height: 140px;

                @include screen-md {

                    width: 600px;
                    height: 280px;

                }

                @include screen-lg {

                    width: 910px;
                    height: 385px;

                }

            }

        }

        .parkingdoor-wireless-icons {

            display: inline-block;
            width: 100%;
            text-align: center;

            .logo {

                width: 100%;
                height: auto;
                margin-bottom: 50px;

            }

            .btn-download-store {

                display: block;
                width: 240px;
                height: 80px;
                margin: 0 auto 20px auto;

            }

            .icon-parkingdoor-come-in {

                width: 230px;
                height: 110px;


            }

        }

        .parkingdoor-wireless-info {

            .btn {

                font-family: Maax, sans-serif;
                font-size: 16px;
                text-transform: uppercase;
                text-decoration: none;
                color: $aluval-white;
                background: $aluval-blue-dark;
                padding: 5px 30px;
                border-radius: 0;

            }
        }

        .parkingdoor-wireless-separator {

            display: inline-block;
            width: 100%;
            height: 10px;
            padding: 0;
            margin: 30px 0 30px 0;
            background: $aluval-orange;

            @include screen-md {

                margin: 120px 0 120px 0;

            }

        }

    }

    .parkingdoor-wireless-separator {

        display: inline-block;
        width: 100%;
        padding: 10px 0 10px 0;
        margin: 30px 0 30px 0;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        @include screen-md {

            margin: 120px 0 120px 0;

        }

    }

}
.winperfil-header {

    display: inline-block;
    width: 100%;

    .winperfil-header-bg {

        height: 240px;
        background-size: cover;
        background-position: center 50%;
        background-repeat: no-repeat;

    }

}
@font-face {
    font-family: 'DolceVita';
    src: url('../fonts/dolce-vita-heavy-bold.woff') format('woff'),
        url('../fonts/dolce-vita-heavy-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Maax';
    src: url('../fonts/maax.woff') format('woff'),
    url('../fonts/maax.ttf') format('truetype');
}

@font-face {
    font-family: 'MaaxMedium';
    src: url('../fonts/maax-medium.woff') format('woff'),
    url('../fonts/maax-medium.ttf') format('truetype');
}

@font-face {
    font-family: 'MaaxBold';
    src: url('../fonts/maax-bold.woff') format('woff'),
    url('../fonts/maax-bold.ttf') format('truetype');
}
.register-header {

    display: inline-block;
    width: 100%;

    .register-header-bg {

        height: 530px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        @include screen-fullhd {

            height: 700px;
            background-size: cover;
            background-position: center 85%;

        }

    }

    .register-header-description-holder {

        text-align: center;

        .register-header-title {

            h1 {

                font-size: 30px;
                text-transform: uppercase;
                text-align: center;
                padding: 50px 0 0 0;
                color: $aluval-brown;
                line-height: 30px;

                @include screen-md {

                    font-size: 36px;

                }

                @include screen-lg{

                    padding: 70px 0 0 0;

                }

                .icon-slash {

                    width: 45px;
                    height: 45px;
                    top: 10px;

                    @include screen-md {

                    }

                }

            }

            p {

                font-size: 14px;
                color: $aluval-black-grey;
                margin-top: 30px;
                text-align: left;

                strong {

                    font-family: MaaxBold, sans-serif;

                }

                @include screen-md {

                    font-size: 18px;

                }

            }

        }

    }

}
.contact-locations {

    margin-top: 50px;
    margin-bottom: 50px;

    .contact-locations-holder {

        text-align: center;

        .contact-locations-list {

            text-align: left;
            border-top: 1px solid $aluval-blue;
            padding-top: 50px;

            ul {

                list-style: none;
                margin: 0 -10px;
                padding: 0;

                li {

                    display: inline-block;
                    height: 60px;
                    width: 100%;
                    margin: 0 0 40px 0;
                    padding: 0 10px;
                    float: left;
                    z-index: 1;

                    @include screen-md {

                        width: 50%;

                    }

                    @include screen-lg {

                        width: 33.33%;

                    }

                    @include screen-xl {

                        width: 25%;

                    }

                    .contact-locations-list-item {

                        position: absolute;
                        top: 0;
                        left: 10px;
                        right: 10px;
                        height: 340px;
                        max-height: 60px;
                        overflow: hidden;

                        @extend .transition-ease-in-out;

                        .contact-locations-list-item-header {

                            display: inline-block;
                            width: 100%;
                            height: 60px;
                            background: $aluval-white;
                            border-bottom: 1px solid $aluval-blue;

                            .title {

                                width: 90%;
                                font-size: 20px;
                                text-transform: uppercase;
                                color: $aluval-blue;
                                float: left;
                                padding: 0 20px;

                                @include screen-md {

                                    font-size: 14px;

                                }

                                @include screen-lg {

                                    font-size: 16px;

                                }

                                @include screen-fullhd {

                                    font-size: 20px;

                                }

                            }

                            .icon {

                                position: absolute;
                                top: -7px;
                                right: 0;
                                width: 32px;
                                height: 32px;
                                cursor: pointer;

                                @extend .icon-plus-blue;

                            }

                        }

                        .contact-locations-list-item-content {

                            top: -8px;
                            padding: 0 10px 20px 10px;
                            background: $aluval-chronology-bg;

                            .contact-locations-list-item-content-inner {

                                height: 260px;
                                background: $aluval-white;
                                padding: 20px 10px 20px 10px;

                                .address {

                                    font-size: 16px;
                                    color: $aluval-black-grey;
                                    margin: 0;
                                    padding: 0;

                                    @include screen-fullhd {

                                        font-size: 18px;

                                    }

                                }

                                .mail,
                                .video {

                                    display: block;
                                    color: $aluval-brown;
                                    font-size: 18px;
                                    text-decoration: none;

                                    @include screen-fullhd {

                                        font-size: 20px;

                                    }

                                }

                                .video {

                                    color: $aluval-blue;

                                }

                                .icon-marker {

                                }

                            }

                        }

                    }

                    &.open {

                        z-index: 9;

                        .contact-locations-list-item {

                            position: absolute;
                            max-height: 340px;

                            .contact-locations-list-item-header {

                                .icon {

                                    @extend .icon-minus-blue;

                                }

                            }

                            .contact-locations-list-item-content {


                            }

                        }
                    }
                }

            }

        }

    }

}
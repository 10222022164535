.locations-header {

    display: inline-block;
    width: 100%;

    .locations-header-bg {

        height: 330px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

    }

    .locations-header-description-holder {

        text-align: center;

        .locations-header-menu {

            width: 100%;
            float: left;
            margin-top: -63px;
            text-align: left;

            @include screen-lg {

                width: 35%;

            }

            ul {

                list-style: none;
                padding: 0;
                margin: 0;

                li {

                    padding: 0;
                    margin: 0;
                    text-transform: uppercase;
                    color: $aluval-black-light;

                    &.title {

                        font-family: 'Maax', sans-serif;
                        font-size: 36px;
                        font-weight: 300;
                        line-height: 55px;
                        color: #fff;
                        padding: 7px 30px 0 30px;
                        background: $aluval-brown;
                        border-bottom: 2px solid $aluval-white;

                    }

                    &.active {

                        background: $aluval-blue;

                        a {

                            color: #fff;
                            font-size: 16px;

                        }

                    }

                    a {

                        display: block;
                        text-decoration: none;
                        color: $aluval-black-light;
                        font-size: 16px;
                        text-transform: uppercase;
                        padding: 5px 30px 0 30px;
                        border-bottom: 2px solid $aluval-blue;

                        .icon-arrow-down {

                            margin-bottom: -10px;
                            top: -4px;

                        }

                    }

                }

            }

        }

        .locations-header-title {

            width: 100%;
            float: left;
            text-align: left;

            @include screen-lg {

                width: 65%;

            }

            h1 {


                font-size: 36px;
                text-transform: uppercase;
                padding: 50px 0 0 0;
                color: $aluval-brown;
                line-height: 30px;

                @include screen-lg{

                    padding: 70px 0 0 50px;

                }

                .icon-slash {

                    width: 45px;
                    height: 45px;
                    top: 10px;

                }

            }

        }

        .locations-header-description-inner {

            padding: 50px 0;

            p {

                font-weight: 300;
                color: #666;
                font-size: 18px;
                text-align: justify;

                strong {

                    color: $aluval-blue;

                }

            }

            .icon-aluval {
                float: left;
                height: 95px;
                width: 95px;
                margin: -10px 20px 0 0;
            }

        }

    }

}
.parkingdoor-control {

    .parkingdoor-control-holder {

        text-align: center;

        .parkingdoor-control-title {

            margin: 15px 0 70px 0;

            h2 {

                font-size: 16px;
                font-family: Max, sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 22px;
                color: $aluval-blue-dark;
                margin: 0;
                padding: 0;

                @include screen-md {

                    font-size: 28px;
                    line-height: 20px;

                }

                @include screen-lg {

                    font-size: 36px;
                    padding: 40px 0 20px 0;
                    line-height: 45px;

                }

                @include screen-fullhd{

                    font-size: 70px;
                    line-height: 80px;

                }

            }

            .icon-slash-orange {

                width: 30px;
                height: 30px;
                top: 10px;

                @include screen-md {

                    width: 50px;
                    height: 50px;
                    top: 15px;

                }

                @include screen-lg {

                    width: 60px;
                    height: 60px;
                    top: 15px;

                }

            }


        }

        .parkingdoor-control-description {

            p {

                font-family: Maax, sans-serif;
                font-weight: 300;
                font-size: 16px;
                line-height: 22px;
                text-align: justify;
                color: $aluval-blue-dark;
                max-width: 730px;
                margin: 0 auto;

            }

        }

        .parkingdoor-control-timeline {

            padding: 50px 0;

            .parkingdoor-control-timeline-row {

                margin: 0;
                padding: 0;
                clear: both;

                .col-left {

                    display: inline-block;
                    width: 25%;
                    float: left;
                    text-align: right;
                    padding: 20px 10px 20px 0;

                    .icon-parkingdoor-door {

                        width: 50px;
                        height: 30px;

                    }

                    .icon-parkingdoor-connection {

                        width: 40px;
                        height: 50px;

                    }

                    .icon-parkingdoor-profiles {

                        width: 60px;
                        height: 45px;

                    }

                }

                .col-right {

                    display: inline-block;
                    width: 75%;
                    float: left;
                    border-left: 5px solid $aluval-blue-dark-new;
                    padding: 10px 0 10px 20px;

                    p {

                        font-family: Maax, sans-serif;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 26px;
                        text-align: justify;
                        color: $aluval-blue-dark;
                        padding: 0;
                        max-width: 350px;

                    }

                }

                @include screen-md {

                    .col-left {

                        width: 40%;
                        padding: 20px 50px 20px 0;

                        .icon-parkingdoor-door {

                            width: 100px;
                            height: 60px;

                        }

                        .icon-parkingdoor-connection {

                            width: 95px;
                            height: 105px;

                        }

                        .icon-parkingdoor-profiles {

                            width: 115px;
                            height: 85px;

                        }

                    }

                    .col-right {

                        width: 60%;
                        padding: 10px 0 10px 50px;

                    }

                }

            }

        }

        .parkingdoor-control-info {

            .btn {

                font-family: Maax, sans-serif;
                font-size: 16px;
                text-transform: uppercase;
                text-decoration: none;
                color: $aluval-white;
                background: $aluval-blue-dark;
                padding: 5px 30px;
                border-radius: 0;

            }
        }

        .parkingdoor-control-separator {

            display: inline-block;
            width: 100%;
            height: 10px;
            padding: 0;
            margin: 30px 0 30px 0;
            background: $aluval-orange;

            @include screen-md {

                margin: 120px 0 120px 0;

            }

        }

    }

    .parkingdoor-control-separator {

        display: inline-block;
        width: 100%;
        padding: 10px 0 10px 0;
        margin: 30px 0 30px 0;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        @include screen-md {

            margin: 120px 0 120px 0;

        }

    }

}
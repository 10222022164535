.products-extra-title {

    .products-extra-title-holder {

        text-align: center;
        padding: 0 0 20px 0;

        @include screen-md {

            padding: 0 0 60px 0;

        }

        .products-extra-title-inner {

            h2 {

                display: inline-block;
                font-size: 24px;
                text-transform: uppercase;
                text-align: left;
                padding: 30px 0 0 0;
                color: $aluval-brown;
                line-height: 30px;

                @include screen-md {

                    font-size: 36px;
                    padding: 70px 0 0 0;
                    max-width: 60%;

                }

                @include screen-fullhd {

                    font-size: 60px;
                    line-height: 40px;
                    text-align: center;
                    max-width: 65%;

                }

                .icon-slash {

                    width: 30px;
                    height: 30px;
                    top: 5px;

                    @include screen-md {

                        width: 45px;
                        height: 45px;
                        top: 10px;

                    }

                    @include screen-fullhd {

                        width: 80px;
                        height: 80px;
                        top: 20px;

                    }

                }

            }

        }

    }

}
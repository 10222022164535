.cte-header {

    display: inline-block;
    width: 100%;

    .cte-header-bg {

        height: 330px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

    }

    .cte-header-description-holder {

        text-align: center;

        .cte-header-title {

            width: 100%;
            float: left;
            text-align: left;

            h1 {


                font-size: 36px;
                text-transform: uppercase;
                text-align: center;
                padding: 50px 0 0 0;
                color: $aluval-brown;
                line-height: 30px;

                .icon-slash {

                    width: 45px;
                    height: 45px;
                    top: 10px;

                }

            }

        }

    }

}
.products-samples-list {

    margin: 0 0 20px 0;

    .products-samples-list-holder {

        text-align: center;
        padding: 30px 0 30px 0;
        background: $aluval-chronology-bg;

        .products-samples-list-title {

            margin: 0 0 20px 0;

            h3 {

                font-size: 24px;
                text-transform: uppercase;
                color: $aluval-blue;
                padding: 8px 0 5px 0;
                background: $aluval-white

            }

        }

        .products-samples-list-items {

            margin: 0 -5px;

            ul {

                display: inline-block;
                width: 100%;
                padding: 0;
                margin: 0;
                list-style: none;

                li {

                    display: inline-block;
                    width: 100%;
                    float: left;
                    padding: 0 5px;

                    @include screen-md {

                        width: 50%;

                    }

                    @include screen-lg {

                        width: 33.33%;

                    }

                    @include screen-xl {

                        width: 25%;

                    }

                    .products-samples-list-item {

                        background: $aluval-white;
                        padding: 20px;
                        margin: 0 0 20px 0;

                        .products-samples-list-item-image {

                            height: 180px;
                            overflow: hidden;

                            img {

                                width: 100%;
                                height: auto;
                                max-height: 100%;

                            }

                        }

                        .products-samples-list-item-name {

                            span {

                                font-size: 18px;
                                color: $aluval-black-grey;

                            }

                        }

                    }


                }

            }

        }

        .products-samples-list-advice {

            text-align: left;
            margin: 50px 0 50px 0;

            .icon-nualicoat {

                width: 180px;
                height: 160px;
                margin-top: -60px;
                margin-bottom: -75px;

            }

            p {

                font-size: 18px;
                color: $aluval-black-grey;

            }

        }

    }

    &.solid-color {

        .products-samples-list-holder {

            .products-samples-list-items {

                ul {

                    li {

                        width: 100%;

                        @include screen-md {

                            width: 33.33%;

                        }

                        @include screen-lg {

                            width: 25%;

                        }

                        @include screen-xl {

                            width: 16.66%;

                        }

                        .products-samples-list-item {

                            .products-samples-list-item-image {

                                height: 120px;

                            }

                        }

                    }

                }

            }

        }

    }

}
.house-brands {

    margin: 120px 0 20px 0;

    .house-brands-holder {

        text-align: center;

        .house-brands-items {

            .house-brands-item {

                margin: 0 0 120px 0;

                .icon-aluval-hogar {

                    width: 280px;
                    height: 90px;

                }

                .icon-lecube {

                    width: 170px;
                    height: 200px;

                }

            }

        }

    }

}
.cte-interactive-map {

    display: inline-block;
    width: 100%;
    padding: 50px 0 50px 0;
    background-image: url('../img/bg-section.png');
    background-repeat: repeat;

    .cte-interactive-map-holder {

        text-align: center;
        background: $aluval-grey;
        padding: 10px 10px 10px 10px;

        .cte-interactive-map-content {

            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border: 2px solid $aluval-white;

            .cte-interactive-map-content-map {

                #location-map-asturias,
                #location-map-pais-vasco,
                #location-map-aragon,
                #location-map-navarra,
                #location-map-galicia-1,
                #location-map-galicia-2,
                #location-map-la-rioja,
                #location-map-leon,
                #location-map-castilla-leon,
                #location-map-burgos,
                #location-map-soria,
                #location-map-cantabria,
                #location-map-lerida,
                #location-map-gerona,
                #location-map-barcelona,
                #location-map-tarragona,
                #location-map-comunitat-valenciana,
                #location-map-avila,
                #location-map-madrid,
                #location-map-castilla-la-mancha,
                #location-map-toledo,
                #location-map-extremadura,
                #location-map-murcia,
                #location-map-andalucia,
                #location-map-jaen,
                #location-map-granada,
                #location-map-andalucia-2,
                #location-map-mallorca,
                #location-map-ibiza,
                #location-map-menorca {

                    &:hover,
                    & path:hover {

                        fill: $aluval-brown;
                        cursor: pointer;

                    }

                }

            }

            .cte-interactive-map-footer {

                position: relative;
                max-width: 850px;
                margin: 0 auto;
                padding: 10px 0 50px 0;

                .icon-holder {

                    position: absolute;
                    top: 15px;
                    left: 0;

                    .icon-aluval {

                        height: 75px;
                        width: 75px;

                    }

                }

                .legend {

                    margin: 0 0 0 90px;

                    table {

                        width: 100%;
                        border-collapse: separate;
                        border-spacing: 5px;

                        tr {

                            td {

                                &.cell-title {

                                    color: $aluval-blue;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    text-align: left;

                                }

                                &.cell-transparent {

                                    width: 25%;
                                    color: $aluval-black-grey;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    text-align: left;

                                }

                                &.cell-zone-a,
                                &.cell-zone-b,
                                &.cell-zone-c,
                                &.cell-zone-d,
                                &.cell-zone-e {

                                    width: 15%;
                                    color: $aluval-white;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    text-align: center;
                                    padding: 5px 0 3px 0;

                                }

                                &.cell-zone-a {

                                    background: $aluval-zone-a;


                                }

                                &.cell-zone-b {

                                    background: $aluval-zone-b;

                                }

                                &.cell-zone-c {

                                    background: $aluval-zone-c;

                                }

                                &.cell-zone-d {

                                    background: $aluval-zone-d;

                                }

                                &.cell-zone-e {

                                    background: $aluval-zone-e;

                                }

                            }

                        }

                    }

                }

            }

        }

    }
}
.services-stats {

    display: inline-block;
    width: 100%;
    text-align: center;

    .services-stats-holder {

        .services-stats-title {

            h2 {

                font-size: 28px;
                text-align: left;
                text-transform: uppercase;
                color: $aluval-brown;
                margin: 0 0 20px 0;
                padding: 0;

                @include screen-fullhd {

                    font-size: 40px;

                }

            }

        }

        .services-stats-details {

            display: table;
            width: 100%;
            margin-bottom: 80px;

            .services-stats-detail-image {

                display: block;
                width: 100%;
                height: 200px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                margin-bottom: 30px;

                @include screen-md {

                    height: 380px;

                }

                @include screen-lg {

                    display: table-cell;
                    width: 380px;
                    height: 180px;

                }

                @include screen-fullhd {

                    width: 520px;
                    height: 300px;

                }

            }

            .services-stats-detail-info {

                display: table;
                width: 100%;
                text-align: left;
                padding: 0 0 0 0;

                @include screen-lg {

                    padding: 0 0 0 20px;

                }

                .services-stats-detail-info-left,
                .services-stats-detail-info-right {

                    display: table-cell;

                    .detail-line {

                        min-height: 34px;
                        font-size: 16px;
                        padding: 5px 5px 0 20px;
                        border-top: 1px solid transparent;
                        border-bottom: 1px solid transparent;
                        margin: 0 0 5px 0;

                        strong {

                            font-family: MaaxBold, sans-serif;

                        }

                        &.blue {

                            background: rgba($aluval-blue, 0.1);
                            border-color: $aluval-blue;
                            color: $aluval-blue;

                        }

                        &.brown {

                            background: rgba($aluval-brown, 0.1);
                            border-color: $aluval-brown;
                            color: $aluval-brown;

                        }

                        @include screen-md {

                            min-height: 50px;
                            font-size: 29px;

                        }

                        @include screen-lg {

                            min-height: 40px;
                            font-size: 16px;

                        }

                        @include screen-fullhd {

                            min-height: 40px;
                            font-size: 18px;

                        }

                    }

                }

                .services-stats-detail-info-left {

                    width: 220px;

                    @include screen-md {

                        width: 400px;

                    }

                    @include screen-lg {

                        width: 320px;

                    }

                    @include screen-fullhd {

                        width: 400px;

                    }

                }

                .services-stats-detail-info-right {

                    .detail-line {

                        color: $aluval-black-grey !important;

                    }

                }

            }

        }

    }

}
.products-links {

    .products-links-holder {

        text-align: center;

        .products-links-inner {

            padding: 50px 0 50px 0;

            ul {

                display: inline-block;
                width: 100%;
                list-style: none;
                padding: 0;
                margin: 0;

                li {

                    display: inline-block;
                    width: 25%;
                    float: left;
                    padding: 0 10px 0 10px;
                    margin: 0 0 20px 0;

                    a {

                        display: block;
                        font-size: 16px;
                        font-family: 'MaaxBold', sans-serif;
                        text-transform: uppercase;
                        text-align: center;
                        text-decoration: none;
                        color: $aluval-white;
                        padding: 5px 5px 3px 5px;
                        border: none;
                        background: $aluval-blue-new;

                        &:hover {

                            background: $aluval-ocre;

                        }

                    }

                    &.active {

                        a {

                            background: $aluval-ocre;

                        }

                    }

                }

            }

        }

    }

}
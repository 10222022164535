.products-recommendations-general-notes {

    margin: 0 0 50px 0;

    .products-recommendations-general-notes-holder {

        text-align: center;

        .products-recommendations-general-notes-title {

            margin: 0 0 40px 0;

            h1 {


                font-size: 36px;
                text-transform: uppercase;
                padding: 0 0 20px 0;
                color: $aluval-brown;
                line-height: 30px;

                @include screen-md {

                    padding: 40px 160px 0 80px;

                }

                @include screen-fullhd {

                    font-size: 50px;
                    padding: 80px 0 20px 80px;

                }

                .icon-slash {

                    width: 40px;
                    height: 40px;
                    top: 10px;

                    @include screen-fullhd {

                        width: 80px;
                        height: 80px;
                        top: 20px;

                    }

                }

            }

        }

        .products-recommendations-general-notes-description {

            text-align: left;

            p {

                font-size: 18px;
                color: $aluval-black-grey;

                .text-blue {

                    color: $aluval-blue;

                }

            }

        }

        .products-recommendations-general-notes-list {

            ul {

                list-style: none;
                margin: 0;
                padding: 0;

                li {

                    margin: 0 0 10px 0;
                    padding: 0 0 10px 0;
                    border-bottom: 1px solid $aluval-blue;

                    p {

                        text-align: left;
                        font-size: 18px;
                        color: $aluval-black-grey;
                        margin: 0;
                        padding: 0;

                        strong {

                            font-family: 'MaaxBold', sans-serif;

                        }

                    }
                }
            }

        }

    }

}
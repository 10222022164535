.planters-description {

    .planters-description-holder {

        text-align: center;

        .planters-description-inner {

            @include screen-lg {

                width: 90%;
                margin: 0 auto;

            }

            @include screen-xl {

                width: 70%;
                margin: 0 auto;

            }

            .planters-description-fake-border {

                position: absolute;
                display: inline-block;

                &.top {

                    top: 0;

                }

                &.right {

                    right: 0;

                }

                &.bottom {

                    bottom: 0;
                }

                &.left {

                    left: 0;

                }

                &.horizontal {

                    height: 5px;
                    width: 80px;
                    background: rgba($aluval-blue-new, 0.8);

                    @include screen-lg {

                        height: 18px;
                        width: 240px;

                    }

                }

                &.vertical {

                    height: 80px;
                    width: 5px;
                    background: $aluval-blue-new;

                    &.top {

                        top: 5px;
                    }

                    &.bottom {

                        bottom: 5px;

                    }

                    @include screen-lg {

                        height: 240px;
                        width: 18px;

                        &.top {

                            top: 18px;
                        }

                        &.bottom {

                            bottom: 18px;

                        }

                    }

                }

            }

            .planters-description-title {

                display: inline-block;
                text-align: center;
                padding: 85px 0 50px 0;

                .planters-description-title-left {

                    img {

                        display: inline-block;
                        height: auto;
                        width: 100%;
                        max-width: 200px;

                    }

                }

                .planters-description-title-right {

                    h3 {

                        font-family: Maax, sans-serif;
                        font-weight: 300;
                        font-size: 26px;
                        text-transform: uppercase;
                        color: $aluval-black;

                        .title-blue {

                            font-weight: 600;
                            color: $aluval-blue-new;

                        }

                    }

                }

                @include screen-lg {

                    display: table;
                    padding: 85px 0 50px 0;
                    margin: 0 auto;

                    .planters-description-title-left {

                        width: 50%;
                        display: table-cell;
                        vertical-align: middle;

                    }

                    .planters-description-title-right {

                        width: 50%;
                        display: table-cell;
                        vertical-align: middle;

                        h3 {

                            font-size: 40px;
                            line-height: 32px;

                        }

                    }

                }

            }

        }
    }

    .planters-description-separator {

        display: inline-block;
        width: 100%;
        padding: 10px 0 10px 0;
        margin: 30px 0 10px 0;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        @include screen-md {

            margin: 180px 0 120px 0;

        }

    }
}
.house-products-gallery {

    margin: 0 0 120px 0;

    .house-products-gallery-holder {

        text-align: center;

        .house-products-gallery-title {

            margin: 0 0 50px 0;

            h1 {

                font-size: 18px;
                font-family: Max, sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 22px;
                color: $aluval-blue-dark;
                margin: 0;
                padding: 0;

                @include screen-md {

                    font-size: 36px;
                    padding: 40px 0 20px 0;
                    line-height: 45px;

                }

                @include screen-fullhd{

                    font-size: 50px;
                    line-height: 80px;

                }

            }

            .icon-slash {

                width: 30px;
                height: 30px;
                top: 5px;

                @include screen-md {

                    width: 60px;
                    height: 60px;
                    top: 15px;

                }

            }

        }

    }

    .house-products-gallery-inner {

        display: inline-block;
        width: 100%;
        padding: 30px 0;
        overflow: hidden;
        background-image: url('../img/bg-section.png');
        background-repeat: repeat;

        .house-products-gallery-images {

            margin: 0 -15px;

            .house-products-gallery-images-item {

                display: inline-block;
                width: 100%;
                height: 450px;
                float: left;
                padding: 0 15px;
                margin: 0 0 20px 0;

                .house-products-gallery-image {

                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;

                }

                @include screen-lg {

                    height: 250px;
                    width: 33.33%;

                }

                @include screen-xl {

                    height: 450px;
                    margin: 0 0;

                }

            }

        }

    }

}
.products-recommendations-items {

    .products-recommendations-items-holder {

        text-align: center;

        .products-recommendations-items-description {

            text-align: left;

            p {

                font-size: 18px;
                color: $aluval-blue;

            }

        }

        .products-recommendations-items-list {

            text-align: left;

            ul {

                list-style: none;
                padding: 0;
                margin: 0;

                li {

                    margin: 0 0 20px 0;
                    padding: 5px 30px 3px 30px;
                    border: 1px solid transparent;
                    border-radius: 5px;

                    &:nth-child(odd) {

                        background: rgba($aluval-blue, 0.4);
                        border-color: $aluval-blue;

                    }

                    &:nth-child(even) {

                        background: rgba($aluval-ocre, 0.3);
                        border-color: $aluval-ocre;

                    }

                    p {

                        font-size: 18px;
                        color: $aluval-black-grey;
                        padding: 0;
                        margin: 0;

                    }

                }

            }

        }

    }

}
.company-chronology {

    padding: 30px 0 0 0;

    .company-chronology-title {

        text-align: center;

        .icon-slash {

            height: 30px;
            width: 30px;
            top: 6px;

        }

        h3 {

            display: inline-block;
            font-family: 'MaaxMedium',sans-serif;
            text-transform: uppercase;
            color: #734d3a;
            font-size: 28px;
            margin: 0;

        }

    }

    .company-chronology-holder {

        padding-top: 80px;

        .company-chronology-items {

            display: inline-block;
            width: 100%;
            /*height: 900px;*/
            margin-bottom: 40px;

            .company-chronology-item-holder {

                display: none;
                width: 100%;
                padding: 0 10px;

                @include screen-lg {

                    padding: 0 30px;

                }

                &.active {

                    display: inline-block;

                }

                .company-chronology-item-box {

                    height: 380px;
                    width: 100%;
                    float: left;
                    border: 4px solid $aluval-chronology-border;
                    margin-bottom: 60px;

                    @include screen-lg {

                        width: 33.33%;
                        margin-bottom: 0;

                    }

                    @include screen-fullhd {

                        height: 450px;

                    }

                    .company-chronology-item-box-header {

                        position: absolute;
                        top: -55px;
                        left: 0;
                        right: 0;
                        text-align: center;
                        z-index: 2;

                        .company-chronology-item-header-sphere {

                            display: inline-block;
                            width: 100px;
                            height: 100px;
                            border: 10px solid $aluval-chronology-border;
                            border-radius: 100px;
                            color: $aluval-white;
                            line-height: 90px;
                            font-size: 30px;

                        }

                    }

                    .company-chronology-item-box-content {

                        display: inline-block;
                        height: 100%;
                        width: 100%;
                        overflow: auto;

                        .company-chronology-item-box-content-text {

                            position: absolute;
                            display: table;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            padding: 35px 25px 50px 25px;

                            p {

                                display: table-cell;
                                vertical-align: middle;
                                text-align: center;
                                font-size: 18px;
                                padding: 20px 15px;
                                background: $aluval-chronology-bg;

                                @include screen-xl {

                                    padding: 20px 25px;

                                }

                                @include screen-fullhd {

                                    font-size: 20px;

                                }

                                strong {

                                    font-family: 'MaaxBold', sans-serif;

                                }

                            }

                        }

                    }

                    &.top-left,
                    &.top-right,
                    &.bottom-left,
                    &.bottom-right,
                    &.bottom-fake-left,
                    &.bottom-fake-right {

                        &::after {

                            content: ' ';
                            display: none;
                            position: absolute;
                            width: 15px;
                            height: 15px;
                            border-radius: 15px;
                            background: $aluval-chronology-border;

                            @include screen-lg {

                                display: block;

                            }

                        }

                        &::before {

                            content: ' ';
                            position: absolute;
                            display: none;
                            height: 4px;
                            width: 30px;
                            background: $aluval-chronology-border;

                            @include screen-lg {

                                display: block;

                            }

                        }

                    }

                    &.top-left {

                        @include screen-lg {
                            border-right: 0;
                        }

                        .company-chronology-item-box-header {
                            .company-chronology-item-header-sphere {
                                background: $aluval-chronology-top-left;
                            }
                        }

                        &::after {

                            top: -10px;
                            left: -40px;

                        }

                        &::before {

                            top: -4px;
                            left: -30px;

                        }

                    }

                    &.top-middle {

                        @include screen-lg {

                            border-right: 0;

                        }

                        .company-chronology-item-box-header {
                            .company-chronology-item-header-sphere {
                                background: $aluval-chronology-top-middle;
                            }
                        }
                    }

                    &.top-right {

                        .company-chronology-item-box-header {
                            .company-chronology-item-header-sphere {
                                background: $aluval-chronology-top-right;
                            }
                        }

                        &::after {

                            top: -10px;
                            right: -40px;

                        }

                        &::before {

                            top: -4px;
                            right: -30px;

                        }

                    }

                    &.bottom-left {

                        border-top: 0;
                        border-right: 0;

                        display: none;

                        @include screen-lg {
                            display: block;
                        }

                        .company-chronology-item-box-header {
                            .company-chronology-item-header-sphere {
                                background: $aluval-chronology-bottom-left;
                            }
                        }

                        &::after {

                            top: -10px;
                            left: -40px;

                        }

                        &::before {

                            top: -4px;
                            left: -30px;

                        }

                    }

                    &.bottom-middle {

                        @include screen-lg {

                            border-top: 0;
                            border-right: 0;

                        };

                        .company-chronology-item-box-header {
                            .company-chronology-item-header-sphere {
                                background: $aluval-chronology-bottom-middle;
                            }
                        }
                    }

                    &.bottom-right {

                        @include screen-lg {
                            border-top: 0;
                        }

                        .company-chronology-item-box-header {
                            .company-chronology-item-header-sphere {
                                background: $aluval-chronology-bottom-right;
                            }
                        }

                        &::after {

                            top: -10px;
                            right: -40px;


                            display: none;
                            @include screen-lg {
                                display: block;
                            }

                        }

                        &::before {

                            top: -4px;
                            right: -30px;

                            display: none;
                            @include screen-lg {
                                display: block;
                            }

                        }

                    }

                    &.bottom-fake-left,
                    &.bottom-fake-right {

                        height: 0;
                        width: 100%;
                        border: 0;

                    }

                    &.bottom-fake-left {

                        display: none;

                        @include screen-lg {
                            display: block;
                        }

                        float: left;

                        &::after {

                            top: -10px;
                            left: -35px;

                        }

                        &::before {

                            top: -4px;
                            left: -25px;

                        }

                    }
                    &.bottom-fake-right {

                        display: none;

                        @include screen-lg {
                            display: block;
                        }

                        float: right;

                        &::after {

                            top: -10px;
                            right: -35px;

                        }

                        &::before {

                            top: -4px;
                            right: -25px;

                        }

                    }

                }

            }

        }

        .company-chronology-paginator {

            text-align: right;
            padding: 0 20px;

            .paginator-left,
            .paginator-right {
                display: inline-block;
                top: -3px;
            }

            .paginator-items {

                display: inline-block;
                list-style: none;
                margin: 0;
                padding: 0;

                li {

                    float: left;
                    padding: 0 12px;

                    a {

                        text-decoration: none;
                        color: $aluval-chronology-border;
                        font-size: 20px;
                        font-family: 'MaaxBold', sans-serif;

                    }

                    &:hover,
                    &.active {

                        a {

                            color: $aluval-chronology-paginator-active;

                        }

                    }

                    & + li {

                        a::before {

                            content: '|';
                            position: absolute;
                            left: -15px;
                            font-size: 20px;
                            color: $aluval-chronology-border;

                        }

                    }

                }

            }

            .paginator-left {

            }

            .paginator-right {

            }

        }

    }

}